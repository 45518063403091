// src/app/core/ui/toast/toast.component.ts
import { Component, OnInit } from '@angular/core';
import { ToastService, ToastMessage } from '../../services/toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
    toasts: ToastMessage[] = [];

    constructor(private toastService: ToastService) {}

    ngOnInit(): void {
        this.toastService.toastState.subscribe((toast) => {
            this.toasts.push(toast);
            setTimeout(() => this.removeToast(toast), 4000);
        });
    }

    removeToast(toast: ToastMessage): void {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }
}
