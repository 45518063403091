/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiScadenzeScadenzeCorpoListGet } from '../fn/scadenze/api-scadenze-scadenze-corpo-list-get';
import { ApiScadenzeScadenzeCorpoListGet$Params } from '../fn/scadenze/api-scadenze-scadenze-corpo-list-get';
import { apiScadenzeScadenzeVigileListGet } from '../fn/scadenze/api-scadenze-scadenze-vigile-list-get';
import { ApiScadenzeScadenzeVigileListGet$Params } from '../fn/scadenze/api-scadenze-scadenze-vigile-list-get';
import { ScadenzaCorpoDto } from '../models/scadenza-corpo-dto';
import { ScadenzaVigileDto } from '../models/scadenza-vigile-dto';

@Injectable({ providedIn: 'root' })
export class ScadenzeService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiScadenzeScadenzeVigileListGet()` */
    static readonly ApiScadenzeScadenzeVigileListGetPath = '/api/scadenze/scadenze-vigile-list';

    /**
     * Carica tutti le scadenze di un vigile data la sua matricola.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiScadenzeScadenzeVigileListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiScadenzeScadenzeVigileListGet$Response(
        params?: ApiScadenzeScadenzeVigileListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<ScadenzaVigileDto>>> {
        return apiScadenzeScadenzeVigileListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica tutti le scadenze di un vigile data la sua matricola.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiScadenzeScadenzeVigileListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiScadenzeScadenzeVigileListGet(
        params?: ApiScadenzeScadenzeVigileListGet$Params,
        context?: HttpContext
    ): Observable<Array<ScadenzaVigileDto>> {
        return this.apiScadenzeScadenzeVigileListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<ScadenzaVigileDto>>): Array<ScadenzaVigileDto> => r.body)
        );
    }

    /** Path part for operation `apiScadenzeScadenzeCorpoListGet()` */
    static readonly ApiScadenzeScadenzeCorpoListGetPath = '/api/scadenze/scadenze-corpo-list';

    /**
     * Carica la lista delle scadenze di tutti i vigili dato l'id del corpo, se non specificatoc arica quelle del corpo dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiScadenzeScadenzeCorpoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiScadenzeScadenzeCorpoListGet$Response(
        params?: ApiScadenzeScadenzeCorpoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<ScadenzaCorpoDto>>> {
        return apiScadenzeScadenzeCorpoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista delle scadenze di tutti i vigili dato l'id del corpo, se non specificatoc arica quelle del corpo dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiScadenzeScadenzeCorpoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiScadenzeScadenzeCorpoListGet(
        params?: ApiScadenzeScadenzeCorpoListGet$Params,
        context?: HttpContext
    ): Observable<Array<ScadenzaCorpoDto>> {
        return this.apiScadenzeScadenzeCorpoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<ScadenzaCorpoDto>>): Array<ScadenzaCorpoDto> => r.body)
        );
    }
}
