import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, Injector } from '@angular/core';

import { AppConfig } from './app-config.models';
import { ConfigReaderService } from './config-reader.service';

@Injectable()
export class AppConfigLoaderService extends ConfigReaderService<AppConfig> {
    public static loaderForFactory(loader: AppConfigLoaderService): () => Promise<void> {
        return (): Promise<void> => loader.read();
    }

    public static getterForFactory(loader: AppConfigLoaderService): AppConfig | null {
        return loader.config;
    }

    constructor(httpClient: HttpClient, injector: Injector) {
        super(httpClient, `./assets/configs/app-config.json`, AppConfig, injector);
    }
}

export const AppConfigInizializer = {
    provide: APP_INITIALIZER,
    useFactory: AppConfigLoaderService.loaderForFactory,
    deps: [AppConfigLoaderService],
    multi: true,
};

export const AppConfigGetter = {
    provide: AppConfig,
    useFactory: AppConfigLoaderService.getterForFactory,
    deps: [AppConfigLoaderService],
};
