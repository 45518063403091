<div class="container-xxl">
    <div class="grid h-full">
        <div class="col-12">
            <p-tabView>
                <p-tabPanel header="Scadenze Vigile">
                    <p-card>
                        <p-table
                            [value]="scadenzeVigile"
                            [columns]="columnsVigile"
                            [rowHover]="true"
                            styleClass="p-datatable-gridlines fs-13"
                            [lazy]="false"
                            (onLazyLoad)="loadScadenzeVigile()"
                            [autoLayout]="true"
                            [filterDelay]="0"
                            [loading]="loadingVigileList"
                            editMode="row"
                            dataKey="id"
                        >
                            <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                    <col *ngFor="let col of columns" [style.width]="col.width" />
                                </colgroup>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        <div class="flex align-center">
                                            {{ col.header }}
                                        </div>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contact let-editing="editing" let-ri="rowIndex" let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <ng-container [ngSwitch]="col.field">
                                            <span *ngSwitchCase="'scadenzaVisitaMedica'">
                                                {{ contact[col.field] | date: "longDate" }}
                                            </span>
                                            <span *ngSwitchCase="'scadenzaPatente'">
                                                {{ contact[col.field] | date: "longDate" }}
                                            </span>
                                            <span *ngSwitchCase="'tipoPatente'">
                                                {{ contact[col.field] }}
                                            </span>
                                            <span *ngSwitchDefault>
                                                {{ contact[col.field] }}
                                            </span>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-card>
                </p-tabPanel>
                <p-tabPanel header="Scadenze Corpo" *ngIf="enableViewCorpoList">
                    <p-card>
                        <p-table
                            #dt
                            [value]="scadenzeCorpo"
                            [columns]="columnsCorpo"
                            [rowHover]="true"
                            styleClass="p-datatable-gridlines fs-13"
                            [lazy]="false"
                            (onLazyLoad)="loadSelectBoxAndScadenzeCorpo()"
                            [autoLayout]="true"
                            [filterDelay]="0"
                            [globalFilterFields]="fields"
                            [loading]="loadingCorpoList"
                            editMode="row"
                            dataKey="id"
                            sortField="scadenzaVisitaMedica"
                            [sortOrder]="1"
                        >
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input
                                                        pInputText
                                                        type="text"
                                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Cerca..."
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="showCorpoUnioneSelector">
                                            <div class="col-6">
                                                <div class="field">
                                                    <label class="block">Unione distrettuale</label>
                                                    <p-dropdown
                                                        [options]="unioneList"
                                                        [disabled]="unioneList.length === 1"
                                                        optionLabel="descrizione"
                                                        optionValue="idUnione"
                                                        [(ngModel)]="selectedUnione"
                                                        scrollHeight="400px"
                                                        (onChange)="onUnioneChange($event)"
                                                        placeholder="Seleziona l'unione distrettuale"
                                                    ></p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="field">
                                                    <label class="block">Corpo</label>
                                                    <p-dropdown
                                                        [options]="corpoList"
                                                        optionLabel="descrizione"
                                                        optionValue="idCorpo"
                                                        [(ngModel)]="selectedCorpo"
                                                        scrollHeight="400px"
                                                        (onChange)="onCorpoChange($event)"
                                                        placeholder="Seleziona il corpo"
                                                    ></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                    <col *ngFor="let col of columns" [style.width]="col.width" />
                                </colgroup>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                        <div class="flex align-center">
                                            {{ col.header }}
                                        </div>
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contact let-editing="editing" let-ri="rowIndex" let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <ng-container [ngSwitch]="col.field">
                                            <span *ngSwitchCase="'matricola'">
                                                {{ contact[col.field] }}
                                            </span>
                                            <span *ngSwitchCase="'nome'">
                                                {{ contact[col.field] }}
                                            </span>
                                            <span *ngSwitchCase="'cognome'">
                                                {{ contact[col.field] }}
                                            </span>

                                            <span
                                                *ngSwitchCase="'scadenzaVisitaMedica'"
                                                [ngClass]="{
                                                    red: today.getTime() > contact[col.field]?.getTime(),
                                                    yellow:
                                                        contact[col.field]?.getTime() < in60Days.getTime() &&
                                                        today.getTime() < contact[col.field]?.getTime()
                                                }"
                                            >
                                                {{ contact[col.field] | date: "longDate" }}
                                            </span>
                                            <span
                                                *ngSwitchCase="'scadenzaPatente'"
                                                [ngClass]="{
                                                    red: today.getTime() > contact[col.field]?.getTime(),
                                                    yellow:
                                                        contact[col.field]?.getTime() < in60Days.getTime() &&
                                                        today.getTime() < contact[col.field]?.getTime()
                                                }"
                                            >
                                                {{ contact[col.field] | date: "longDate" }}
                                            </span>
                                            <span *ngSwitchCase="'tipoPatente'">
                                                {{ contact[col.field] }}
                                            </span>
                                            <span *ngSwitchDefault>
                                                {{ contact[col.field] }}
                                            </span>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length">Nessun Dato</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-card>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
