import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Map, View } from 'ol';
import { Coordinate, format } from 'ol/coordinate';
import { Attribution, defaults as DefaultControls, FullScreen, MousePosition, ScaleLine, Zoom, ZoomSlider } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import TileWMS from 'ol/source/TileWMS';
import BingMaps from 'ol/source/BingMaps';
import LayerSwitcher, { GroupLayerOptions } from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { getValueOrError } from '@core/errors/exception';
import { MarcatoreService } from '@core/http-gen/services';
import Select from 'ol/interaction/Select';
import { TetraSourceService } from '@shared/map-layers/tetra-source.service';
import { IdrantiSourceService } from '@shared/map-layers/idranti-source.service';

interface LastProductsByType {
    total: number;
    lastProducts: Array<LastProductByType>;
}

interface LastProductByType {
    productType: string;
    time: number;
    period: string;
}

interface LastUpdate {
    code: string;
    radar: string;
    lastUpdate: string;
}

const BING_API_KEY = 'AsAH6rRTGHZ0UkPTRsyP7Sw4_AHESq7bdakU3lYXd-9guxqA3xz0TxrU-QUG-Nnr';

@Component({
    selector: 'app-ol-map',
    templateUrl: './ol-map.component.html',
    styleUrls: ['./ol-map.component.scss'],
})
export class OlMapComponent implements AfterViewInit {
    @Input() center!: Coordinate;
    @Input() zoom?: number;
    @Input() disableControls?: boolean;
    view?: View;
    Map?: Map;
    @Output() mapReady = new EventEmitter<Map>();
    tetraLayer = new VectorLayer({});
    idrantiLayer = new VectorLayer({});

    // HRD,VMI,SRI,SRT1,SRT3,SRT6,SRT12,SRT24,IR108,RADAR_STATUS,AMV,TEMP,LTG
    private lastUpdates: Array<LastUpdate> = [
        { code: 'TEMP', radar: 'temperature', lastUpdate: '' },
        { code: 'HRD', radar: 'hrd', lastUpdate: '' },
        { code: 'SRT24', radar: 'srt24', lastUpdate: '' },
        { code: 'SRT1', radar: 'srt1', lastUpdate: '' },
        { code: 'VMI', radar: 'vmi', lastUpdate: '' },
    ];

    constructor(
        private zone: NgZone,
        private http: HttpClient,
        private marcatoreService: MarcatoreService,
        private tetraSourceService: TetraSourceService,
        private idrantiSourceService: IdrantiSourceService
    ) {}

    ngAfterViewInit(): void {
        // disabilitato perché il servizio tetra è stato disabilitato
        // this.tetraSourceService.getSource().subscribe((tetraSource) => {
        //     this.tetraLayer.setSource(tetraSource);
        // });

        this.idrantiSourceService.getSource().subscribe((idrantiSource) => {
            this.idrantiLayer.setSource(idrantiSource);
        });

        const requests: Array<Observable<LastProductsByType>> = [];

        this.lastUpdates.forEach((val) => {
            requests.push(
                this.http.get<LastProductsByType>(
                    `https://radar-api.protezionecivile.it/wide/product/findLastProductByType?lang=it&type=${val.code}`
                )
            );
        });

        forkJoin(requests).subscribe((responses) => {
            this.setLastUpdate(responses);

            if (!this.Map) {
                this.zone.runOutsideAngular(() => this.initMap());
            }
            setTimeout(() => this.mapReady.emit(this.Map));
        });
    }

    private initMap(): void {
        this.view = new View({
            center: fromLonLat(this.center),
            zoom: this.zoom,
        });

        /**
         * INIZIALIZZO LAYERS
         */

        // MAPPE DI BASE
        const baseMaps = new LayerGroup({
            title: 'Mappe di base',
            visible: true,
        } as GroupLayerOptions);

        let TMPLayersName = [
            { name: 'Stradale scura', value: 'CanvasDark' },
            { name: 'Stradale', value: 'RoadOnDemand', visible: true },
            { name: 'Aerea', value: 'Aerial', visible: false },
            { name: 'Aerea con etichette', value: 'AerialWithLabelsOnDemand' },
        ];

        TMPLayersName.forEach((element) => {
            const layerTMP = new TileLayer({
                visible: false,
                preload: Infinity,
                source: new BingMaps({
                    key: BING_API_KEY,
                    imagerySet: element.value,
                }),
            });
            layerTMP.set('title', element.name);
            layerTMP.set('type', 'base');
            layerTMP.setVisible(element.visible || false);

            baseMaps.getLayers().push(layerTMP);
        });

        // MAPPE ACCESSORIE PROVINCIA
        // stem:ammcom non c'è più
        const accessoryMaps = new LayerGroup({
            title: 'Mappe provinciali',
            fold: 'close',
            combine: false,
        } as GroupLayerOptions);

        TMPLayersName = [
            { name: 'Confine provinciale', value: 'stem:ammprv', visible: false },
            { name: 'Toponimi', value: 'stem:toponimi', visible: false },
            { name: 'Carta tecnica provinciale 2020', value: 'stem:ctp2020_bn_00' },
            { name: 'Ortofoto 1973', value: 'stem:ofdtn73' },
            { name: 'Ortofoto 1994', value: 'stem:ofdtn94' },
            { name: 'Ortofoto 2015', value: 'stem:ecw-rgb-2015' },
        ];

        TMPLayersName.forEach((element) => {
            const layerTMP = new TileLayer({
                visible: false,
                source: new TileWMS({
                    url: 'https://siat.provincia.tn.it/geoserver/stem/wms',
                    params: {
                        FORMAT: 'image/png',
                        VERSION: '1.1.1',
                        STYLES: '',
                        LAYERS: element.value,
                    },
                    serverType: 'geoserver',
                }),
            });
            layerTMP.set('title', element.name);
            layerTMP.setVisible(element.visible || false);

            accessoryMaps.getLayers().push(layerTMP);
        });

        // RADAR PROTEZIONE CIVILE
        const radarPCMaps = new LayerGroup({
            title: 'Radar Meteo Protezione Civile',
            fold: 'close',
            combine: false,
        } as GroupLayerOptions);

        TMPLayersName = [
            { name: 'Vento', value: 'amv' },
            { name: 'Copertura nuvolosa', value: 'ir108' },
            { name: 'Temperature', value: 'temperature' },
            { name: 'Fulminazioni', value: 'ltg' },
            { name: 'Temporali', value: 'hrd' },
            { name: 'Precipitazioni 24h', value: 'srt24' },
            { name: 'Precipitazioni 1h', value: 'srt1' },
            { name: 'Massima riflettività', value: 'vmi', visible: false },
        ];

        TMPLayersName.forEach((element) => {
            const layerTMP = new TileLayer({
                visible: false,
                source: new TileWMS({
                    url: 'https://radar-geowebcache.protezionecivile.it/service/wms',
                    params: {
                        FORMAT: 'image/png',
                        VERSION: '1.1.1',
                        STYLES: '',
                        TIME: this.findLastUpdate(element),
                        LAYERS: 'radar:' + element.value,
                    },
                    serverType: 'geoserver',
                }),
            });
            layerTMP.set('title', element.name);
            layerTMP.setVisible(element.visible || false);
            radarPCMaps.getLayers().push(layerTMP);
        });

        // MAPPE IDRANTI e TETRA
        const servizioMaps = new LayerGroup({
            title: 'Servizio',
            fold: 'open',
            combine: false,
        } as GroupLayerOptions);

        this.idrantiLayer.set('title', 'Idranti');
        this.idrantiLayer.setVisible(true);
        servizioMaps.getLayers().push(this.idrantiLayer);

        this.tetraLayer.set('title', 'TeTRa');
        this.tetraLayer.setVisible(false);
        servizioMaps.getLayers().push(this.tetraLayer);

        /**
         * CREO MAPPA
         */
        this.Map = new Map({
            layers: [baseMaps, radarPCMaps, accessoryMaps, servizioMaps],
            target: 'map',
            pixelRatio: 1,
            view: this.view,
            controls: [],
        });

        const control = DefaultControls().extend([
            new Attribution(),
            new MousePosition({
                projection: 'EPSG:4326',
                coordinateFormat: function (coordinate: number[] | undefined): string {
                    return format(coordinate || [0, 0], '{y}, {x}', 4);
                },
            }),
            new ScaleLine(),
            new Zoom(),
            new ZoomSlider(),
            new FullScreen(),
        ]);
        const layerSwitcher = new LayerSwitcher({
            activationMode: 'click',
            reverse: true,
            groupSelectStyle: 'children',
        });

        // gestione del click su una feature
        const select = new Select();
        this.Map.addInteraction(select);
        // Here's the event handler that will give you the selected features
        select.on('select', function () {
            // console.log(e.selected);
        });

        if (!this.disableControls) {
            control.forEach((c) => this.Map?.addControl(c));

            this.Map.addControl(layerSwitcher);
        }
    }

    private findLastUpdate(element: { name: string; value: string }): string {
        return getValueOrError(this.lastUpdates.find((s) => s.radar.toLocaleLowerCase() === element.value.toLocaleLowerCase())).lastUpdate;
    }

    private setLastUpdate(responses: Array<LastProductsByType>): void {
        // debugger
        responses.forEach((response) => {
            if (response.lastProducts[0]) {
                const lastUpdate = getValueOrError(this.lastUpdates.find((s) => s.code === response.lastProducts[0].productType));
                lastUpdate.lastUpdate = new Date(response.lastProducts[0].time).toISOString();
            }
        });

        this.lastUpdates.push({
            code: 'ltg',
            radar: 'ltg',
            lastUpdate: getValueOrError(this.lastUpdates.find((s) => s.radar === 'vmi')).lastUpdate,
        });
        this.lastUpdates.push({
            code: 'amv',
            radar: 'amv',
            lastUpdate: getValueOrError(this.lastUpdates.find((s) => s.radar === 'vmi')).lastUpdate,
        });
        this.lastUpdates.push({
            code: 'ir108',
            radar: 'ir108',
            lastUpdate: getValueOrError(this.lastUpdates.find((s) => s.radar === 'vmi')).lastUpdate,
        });
    }
}
