/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiNotificaInvioNotificaPost } from '../fn/notifica/api-notifica-invio-notifica-post';
import { ApiNotificaInvioNotificaPost$Params } from '../fn/notifica/api-notifica-invio-notifica-post';
import { apiNotificaNotificaAllegatoByIdGet } from '../fn/notifica/api-notifica-notifica-allegato-by-id-get';
import { ApiNotificaNotificaAllegatoByIdGet$Params } from '../fn/notifica/api-notifica-notifica-allegato-by-id-get';
import { apiNotificaNotificaAllegatoInfoListGet } from '../fn/notifica/api-notifica-notifica-allegato-info-list-get';
import { ApiNotificaNotificaAllegatoInfoListGet$Params } from '../fn/notifica/api-notifica-notifica-allegato-info-list-get';
import { apiNotificaNotificaArchiviaPost } from '../fn/notifica/api-notifica-notifica-archivia-post';
import { ApiNotificaNotificaArchiviaPost$Params } from '../fn/notifica/api-notifica-notifica-archivia-post';
import { apiNotificaNotificaByIdGet } from '../fn/notifica/api-notifica-notifica-by-id-get';
import { ApiNotificaNotificaByIdGet$Params } from '../fn/notifica/api-notifica-notifica-by-id-get';
import { apiNotificaNotificaListGet } from '../fn/notifica/api-notifica-notifica-list-get';
import { ApiNotificaNotificaListGet$Params } from '../fn/notifica/api-notifica-notifica-list-get';
import { apiNotificaNotificaRimuoviArchiviaPost } from '../fn/notifica/api-notifica-notifica-rimuovi-archivia-post';
import { ApiNotificaNotificaRimuoviArchiviaPost$Params } from '../fn/notifica/api-notifica-notifica-rimuovi-archivia-post';
import { apiNotificaNotificaRimuoviLetturaPost } from '../fn/notifica/api-notifica-notifica-rimuovi-lettura-post';
import { ApiNotificaNotificaRimuoviLetturaPost$Params } from '../fn/notifica/api-notifica-notifica-rimuovi-lettura-post';
import { NotificaAllegatoInfoDto } from '../models/notifica-allegato-info-dto';
import { NotificaDto } from '../models/notifica-dto';
import { NotificaListItemDto } from '../models/notifica-list-item-dto';

@Injectable({ providedIn: 'root' })
export class NotificaService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiNotificaInvioNotificaPost()` */
    static readonly ApiNotificaInvioNotificaPostPath = '/api/notifica/invio-notifica';

    /**
     * Invia una notifica ai destinatari selezionati.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaInvioNotificaPost()` instead.
     *
     * This method sends `Dati della notifica e destinatari a cui deve essere inviata` and handles request body of type `Dati della notifica e destinatari a cui deve essere inviata`.
     */
    apiNotificaInvioNotificaPost$Response(
        params: ApiNotificaInvioNotificaPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiNotificaInvioNotificaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Invia una notifica ai destinatari selezionati.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaInvioNotificaPost$Response()` instead.
     *
     * This method sends `Dati della notifica e destinatari a cui deve essere inviata` and handles request body of type `Dati della notifica e destinatari a cui deve essere inviata`.
     */
    apiNotificaInvioNotificaPost(params: ApiNotificaInvioNotificaPost$Params, context?: HttpContext): Observable<void> {
        return this.apiNotificaInvioNotificaPost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiNotificaNotificaListGet()` */
    static readonly ApiNotificaNotificaListGetPath = '/api/notifica/notifica-list';

    /**
     * Carica la lista delle notifiche associate all-utente corrente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaListGet$Response(
        params?: ApiNotificaNotificaListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<NotificaListItemDto>>> {
        return apiNotificaNotificaListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista delle notifiche associate all-utente corrente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaListGet(params?: ApiNotificaNotificaListGet$Params, context?: HttpContext): Observable<Array<NotificaListItemDto>> {
        return this.apiNotificaNotificaListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<NotificaListItemDto>>): Array<NotificaListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiNotificaNotificaByIdGet()` */
    static readonly ApiNotificaNotificaByIdGetPath = '/api/notifica/notifica-by-id';

    /**
     * Carica una notifica in base al suoi Id, e la imposta come letta da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaByIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaByIdGet$Response(
        params: ApiNotificaNotificaByIdGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<NotificaDto>> {
        return apiNotificaNotificaByIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica una notifica in base al suoi Id, e la imposta come letta da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaByIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaByIdGet(params: ApiNotificaNotificaByIdGet$Params, context?: HttpContext): Observable<NotificaDto> {
        return this.apiNotificaNotificaByIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<NotificaDto>): NotificaDto => r.body)
        );
    }

    /** Path part for operation `apiNotificaNotificaRimuoviLetturaPost()` */
    static readonly ApiNotificaNotificaRimuoviLetturaPostPath = '/api/notifica/notifica-rimuovi-lettura';

    /**
     * Rimuove la lettura della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaRimuoviLetturaPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaRimuoviLetturaPost$Response(
        params: ApiNotificaNotificaRimuoviLetturaPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiNotificaNotificaRimuoviLetturaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Rimuove la lettura della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaRimuoviLetturaPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaRimuoviLetturaPost(params: ApiNotificaNotificaRimuoviLetturaPost$Params, context?: HttpContext): Observable<void> {
        return this.apiNotificaNotificaRimuoviLetturaPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<void>): void => r.body)
        );
    }

    /** Path part for operation `apiNotificaNotificaArchiviaPost()` */
    static readonly ApiNotificaNotificaArchiviaPostPath = '/api/notifica/notifica-archivia';

    /**
     * Imposta l'archiviazione della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaArchiviaPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaArchiviaPost$Response(
        params: ApiNotificaNotificaArchiviaPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiNotificaNotificaArchiviaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Imposta l'archiviazione della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaArchiviaPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaArchiviaPost(params: ApiNotificaNotificaArchiviaPost$Params, context?: HttpContext): Observable<void> {
        return this.apiNotificaNotificaArchiviaPost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiNotificaNotificaRimuoviArchiviaPost()` */
    static readonly ApiNotificaNotificaRimuoviArchiviaPostPath = '/api/notifica/notifica-rimuovi-archivia';

    /**
     * Rimuove l'archiviazione della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaRimuoviArchiviaPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaRimuoviArchiviaPost$Response(
        params: ApiNotificaNotificaRimuoviArchiviaPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiNotificaNotificaRimuoviArchiviaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Rimuove l'archiviazione della notifica da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaRimuoviArchiviaPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaRimuoviArchiviaPost(params: ApiNotificaNotificaRimuoviArchiviaPost$Params, context?: HttpContext): Observable<void> {
        return this.apiNotificaNotificaRimuoviArchiviaPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<void>): void => r.body)
        );
    }

    /** Path part for operation `apiNotificaNotificaAllegatoByIdGet()` */
    static readonly ApiNotificaNotificaAllegatoByIdGetPath = '/api/notifica/notifica-allegato-by-id';

    /**
     * Scarica un allegato in base al suo id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaAllegatoByIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaAllegatoByIdGet$Response(
        params: ApiNotificaNotificaAllegatoByIdGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<{}>> {
        return apiNotificaNotificaAllegatoByIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Scarica un allegato in base al suo id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaAllegatoByIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaAllegatoByIdGet(params: ApiNotificaNotificaAllegatoByIdGet$Params, context?: HttpContext): Observable<{}> {
        return this.apiNotificaNotificaAllegatoByIdGet$Response(params, context).pipe(map((r: StrictHttpResponse<{}>): {} => r.body));
    }

    /** Path part for operation `apiNotificaNotificaAllegatoInfoListGet()` */
    static readonly ApiNotificaNotificaAllegatoInfoListGetPath = '/api/notifica/notifica-allegato-info-list';

    /**
     * Carica le informazioni degli allegati associati alla notifica.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNotificaNotificaAllegatoInfoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaAllegatoInfoListGet$Response(
        params: ApiNotificaNotificaAllegatoInfoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<NotificaAllegatoInfoDto>>> {
        return apiNotificaNotificaAllegatoInfoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica le informazioni degli allegati associati alla notifica.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNotificaNotificaAllegatoInfoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNotificaNotificaAllegatoInfoListGet(
        params: ApiNotificaNotificaAllegatoInfoListGet$Params,
        context?: HttpContext
    ): Observable<Array<NotificaAllegatoInfoDto>> {
        return this.apiNotificaNotificaAllegatoInfoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<NotificaAllegatoInfoDto>>): Array<NotificaAllegatoInfoDto> => r.body)
        );
    }
}
