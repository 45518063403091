import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TipoEntita } from '@core/http-gen/models/tipo-entita';
import { LocalStorageConstants } from '@shared/constants/local-storage.constants';

/**
 * Nel caso in cui un utente abbia più gradi associati, ha la possibilità di agire sul portale distintamente in base al grado
 * Es.: Il presidente della federazione avrà anche il grado di vigile sul proprio corpo
 * Con il grado di presidente potrà vedere tutte le unioni e i corpi, mentre con il grado di Vigile vedrà solo il corpo a cui appartiene
 */
@Injectable()
export class GradoHeaderInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf('https://radar-api.protezionecivile.it') === -1) {
            // TODO: migliorare questo controllo
            const idGradoSelected: number | null =
                localStorage.getItem(LocalStorageConstants.idGradoHeader) != null
                    ? +(<string>localStorage.getItem(LocalStorageConstants.idGradoHeader))
                    : null;
            const tipoEntitaSelected: TipoEntita | null =
                localStorage.getItem(LocalStorageConstants.tipoEntitaHeader) != null
                    ? <TipoEntita>+(<string>localStorage.getItem(LocalStorageConstants.tipoEntitaHeader))
                    : null;
            const idEntitaSelected: number | null =
                localStorage.getItem(LocalStorageConstants.idEntitaHeader) != null
                    ? +(<string>localStorage.getItem(LocalStorageConstants.idEntitaHeader))
                    : null;

            // Se è selezionato un grado aggiungo i valori agli header della chiamata
            if (idGradoSelected != null && tipoEntitaSelected != null && idEntitaSelected != null) {
                request = request.clone({
                    setHeaders: {
                        'X-ID-GRADO': idGradoSelected.toString(),
                        'X-TIPO-ENTITA': (+tipoEntitaSelected).toString(),
                        'X-ID-ENTITA': idEntitaSelected.toString(),
                    },
                });
            }
        }

        return next.handle(request);
    }
}
