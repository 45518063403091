<div class="container-xxl">
    <div class="mt-3">
        <div class="row">
            <h2 class="col-12 col-md-7 my-auto text-start">Lista Selettive</h2>
            <form class="col-12 col-md-5 d-flex my-auto my-md-auto">
                <input class="form-control me-2 flex-grow-1" type="search" placeholder="Cerca... (not implemented)" aria-label="Search" />
                <button class="btn btn-outline-success" type="submit">Cerca</button>
            </form>
        </div>
    </div>

    <div class="table-responsive mt-3">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Corpo</th>
                    <th>Data</th>
                    <th>Evento</th>
                    <th>Dettaglio</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alert of alertCallsList">
                    <td>{{ alert.corpo["CRP_DESCRIZIONE"].toUpperCase() || alert.corpoId }}</td>
                    <td>{{ alert.dataOra | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                    <td>{{ alert.eventoTipologia }}</td>
                    <td class="cell">{{ alert.eventoDettaglio }}</td>
                    <td class="text-end">
                        <a href="/alertcalls/{{ alert.id }}" class="link-primary"> Apri</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-table-pagination
        *ngIf="alertCallsLength > 0"
        [currentPage]="currentPage"
        [totalItems]="alertCallsLength"
        [itemsPerPage]="itemsPerPage"
        (changePage)="getAlertCalls($event)"
    ></app-table-pagination>
</div>
