/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiOrganicoCorpoListConCountGet } from '../fn/organico/api-organico-corpo-list-con-count-get';
import { ApiOrganicoCorpoListConCountGet$Params } from '../fn/organico/api-organico-corpo-list-con-count-get';
import { apiOrganicoCorpoListGet } from '../fn/organico/api-organico-corpo-list-get';
import { ApiOrganicoCorpoListGet$Params } from '../fn/organico/api-organico-corpo-list-get';
import { apiOrganicoGradoListGet } from '../fn/organico/api-organico-grado-list-get';
import { ApiOrganicoGradoListGet$Params } from '../fn/organico/api-organico-grado-list-get';
import { apiOrganicoMansioneListGet } from '../fn/organico/api-organico-mansione-list-get';
import { ApiOrganicoMansioneListGet$Params } from '../fn/organico/api-organico-mansione-list-get';
import { apiOrganicoUnioneListGet } from '../fn/organico/api-organico-unione-list-get';
import { ApiOrganicoUnioneListGet$Params } from '../fn/organico/api-organico-unione-list-get';
import { apiOrganicoVolontarioListByCorpoGet } from '../fn/organico/api-organico-volontario-list-by-corpo-get';
import { ApiOrganicoVolontarioListByCorpoGet$Params } from '../fn/organico/api-organico-volontario-list-by-corpo-get';
import { apiOrganicoVolontarioListByGradoGet } from '../fn/organico/api-organico-volontario-list-by-grado-get';
import { ApiOrganicoVolontarioListByGradoGet$Params } from '../fn/organico/api-organico-volontario-list-by-grado-get';
import { apiOrganicoVolontarioListByMansioneGet } from '../fn/organico/api-organico-volontario-list-by-mansione-get';
import { ApiOrganicoVolontarioListByMansioneGet$Params } from '../fn/organico/api-organico-volontario-list-by-mansione-get';
import { CorpoListConCountItemDto } from '../models/corpo-list-con-count-item-dto';
import { CorpoListItemDto } from '../models/corpo-list-item-dto';
import { GradoCountDto } from '../models/grado-count-dto';
import { MansioneCountDto } from '../models/mansione-count-dto';
import { UnioneDto } from '../models/unione-dto';
import { VvfVolontarioCorpoDto } from '../models/vvf-volontario-corpo-dto';
import { VvfVolontarioGradoDto } from '../models/vvf-volontario-grado-dto';
import { VvfVolontarioMansioneDto } from '../models/vvf-volontario-mansione-dto';

@Injectable({ providedIn: 'root' })
export class OrganicoService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiOrganicoGradoListGet()` */
    static readonly ApiOrganicoGradoListGetPath = '/api/organico/grado-list';

    /**
     * Carica la lista dei gradi presenti sul DB della federazione.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoGradoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoGradoListGet$Response(
        params?: ApiOrganicoGradoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<GradoCountDto>>> {
        return apiOrganicoGradoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei gradi presenti sul DB della federazione.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoGradoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoGradoListGet(params?: ApiOrganicoGradoListGet$Params, context?: HttpContext): Observable<Array<GradoCountDto>> {
        return this.apiOrganicoGradoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<GradoCountDto>>): Array<GradoCountDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoMansioneListGet()` */
    static readonly ApiOrganicoMansioneListGetPath = '/api/organico/mansione-list';

    /**
     * Carica la lista delle mansioni presenti sul DB della federazione.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoMansioneListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoMansioneListGet$Response(
        params?: ApiOrganicoMansioneListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<MansioneCountDto>>> {
        return apiOrganicoMansioneListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista delle mansioni presenti sul DB della federazione.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoMansioneListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoMansioneListGet(params?: ApiOrganicoMansioneListGet$Params, context?: HttpContext): Observable<Array<MansioneCountDto>> {
        return this.apiOrganicoMansioneListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<MansioneCountDto>>): Array<MansioneCountDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoVolontarioListByGradoGet()` */
    static readonly ApiOrganicoVolontarioListByGradoGetPath = '/api/organico/volontario-list-by-grado';

    /**
     * Carica la lista dei vigili che hanno il grado indicato al momento della richiesta.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoVolontarioListByGradoGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByGradoGet$Response(
        params: ApiOrganicoVolontarioListByGradoGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<VvfVolontarioGradoDto>>> {
        return apiOrganicoVolontarioListByGradoGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei vigili che hanno il grado indicato al momento della richiesta.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoVolontarioListByGradoGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByGradoGet(
        params: ApiOrganicoVolontarioListByGradoGet$Params,
        context?: HttpContext
    ): Observable<Array<VvfVolontarioGradoDto>> {
        return this.apiOrganicoVolontarioListByGradoGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<VvfVolontarioGradoDto>>): Array<VvfVolontarioGradoDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoVolontarioListByMansioneGet()` */
    static readonly ApiOrganicoVolontarioListByMansioneGetPath = '/api/organico/volontario-list-by-mansione';

    /**
     * Carica la lista dei vigili che hanno la mansione indicata al momento della richiesta.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoVolontarioListByMansioneGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByMansioneGet$Response(
        params: ApiOrganicoVolontarioListByMansioneGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<VvfVolontarioMansioneDto>>> {
        return apiOrganicoVolontarioListByMansioneGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei vigili che hanno la mansione indicata al momento della richiesta.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoVolontarioListByMansioneGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByMansioneGet(
        params: ApiOrganicoVolontarioListByMansioneGet$Params,
        context?: HttpContext
    ): Observable<Array<VvfVolontarioMansioneDto>> {
        return this.apiOrganicoVolontarioListByMansioneGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<VvfVolontarioMansioneDto>>): Array<VvfVolontarioMansioneDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoVolontarioListByCorpoGet()` */
    static readonly ApiOrganicoVolontarioListByCorpoGetPath = '/api/organico/volontario-list-by-corpo';

    /**
     * Carica la lista dei volontari appartenenti ad un corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoVolontarioListByCorpoGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByCorpoGet$Response(
        params: ApiOrganicoVolontarioListByCorpoGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<VvfVolontarioCorpoDto>>> {
        return apiOrganicoVolontarioListByCorpoGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei volontari appartenenti ad un corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoVolontarioListByCorpoGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoVolontarioListByCorpoGet(
        params: ApiOrganicoVolontarioListByCorpoGet$Params,
        context?: HttpContext
    ): Observable<Array<VvfVolontarioCorpoDto>> {
        return this.apiOrganicoVolontarioListByCorpoGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<VvfVolontarioCorpoDto>>): Array<VvfVolontarioCorpoDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoUnioneListGet()` */
    static readonly ApiOrganicoUnioneListGetPath = '/api/organico/unione-list';

    /**
     * Carica la lista delle unioni distrettuali presenti.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoUnioneListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoUnioneListGet$Response(
        params?: ApiOrganicoUnioneListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UnioneDto>>> {
        return apiOrganicoUnioneListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista delle unioni distrettuali presenti.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoUnioneListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoUnioneListGet(params?: ApiOrganicoUnioneListGet$Params, context?: HttpContext): Observable<Array<UnioneDto>> {
        return this.apiOrganicoUnioneListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UnioneDto>>): Array<UnioneDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoCorpoListGet()` */
    static readonly ApiOrganicoCorpoListGetPath = '/api/organico/corpo-list';

    /**
     * Carica la lista dei corpi, se specificata filtra per unione distrettuale.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoCorpoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoCorpoListGet$Response(
        params?: ApiOrganicoCorpoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<CorpoListItemDto>>> {
        return apiOrganicoCorpoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei corpi, se specificata filtra per unione distrettuale.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoCorpoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoCorpoListGet(params?: ApiOrganicoCorpoListGet$Params, context?: HttpContext): Observable<Array<CorpoListItemDto>> {
        return this.apiOrganicoCorpoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CorpoListItemDto>>): Array<CorpoListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiOrganicoCorpoListConCountGet()` */
    static readonly ApiOrganicoCorpoListConCountGetPath = '/api/organico/corpo-list-con-count';

    /**
     * Carica la lista dei corpi con il contatore dei volontari, se specificata filtra per unione distrettuale.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOrganicoCorpoListConCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoCorpoListConCountGet$Response(
        params?: ApiOrganicoCorpoListConCountGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<CorpoListConCountItemDto>>> {
        return apiOrganicoCorpoListConCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei corpi con il contatore dei volontari, se specificata filtra per unione distrettuale.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOrganicoCorpoListConCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOrganicoCorpoListConCountGet(
        params?: ApiOrganicoCorpoListConCountGet$Params,
        context?: HttpContext
    ): Observable<Array<CorpoListConCountItemDto>> {
        return this.apiOrganicoCorpoListConCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<CorpoListConCountItemDto>>): Array<CorpoListConCountItemDto> => r.body)
        );
    }
}
