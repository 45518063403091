/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NumeroUtileDto } from '../../models/numero-utile-dto';

export interface ApiNumeroUtileNumeroUtileInsertPost$Params {
    /**
     * application/json
     */
    body: NumeroUtileDto;
}

export function apiNumeroUtileNumeroUtileInsertPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiNumeroUtileNumeroUtileInsertPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<boolean>>> {
    const rb = new RequestBuilder(rootUrl, apiNumeroUtileNumeroUtileInsertPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<boolean>>;
        })
    );
}

apiNumeroUtileNumeroUtileInsertPost.PATH = '/api/numero-utile/numero-utile-insert';
