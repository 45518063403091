<div class="container-xxl">
    <div class="grid h-full">
        <div class="col-12">
            <p-tabView>
                <p-tabPanel header="Numeri Utili">
                    <p-table
                        #dt
                        [value]="numeriUtili"
                        [columns]="columns"
                        [rowHover]="true"
                        styleClass="p-datatable-gridlines fs-13"
                        [lazy]="false"
                        (onLazyLoad)="loadContacts()"
                        [autoLayout]="true"
                        [filterDelay]="0"
                        [globalFilterFields]="fields"
                        [loading]="loadingList"
                        editMode="row"
                        dataKey="id"
                    >
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input
                                        pInputText
                                        type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Cerca..."
                                    />
                                </span>
                                <div>
                                    <button
                                        *ngIf="canCreate"
                                        [disabled]="addingNew"
                                        icon="pi pi-plus"
                                        pButton
                                        label="Aggiungi"
                                        class="p-button-sm ml-auto"
                                        (click)="aggiungiInline()"
                                    ></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style.width]="col.width" />
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <div class="flex align-center">
                                        {{ col.header }}
                                    </div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-contact let-editing="editing" let-ri="rowIndex" let-columns="columns">
                            <tr [pEditableRow]="contact">
                                <td *ngFor="let col of columns">
                                    <ng-container [ngSwitch]="col.field">
                                        <span *ngSwitchCase="'dropdown'">
                                            <button
                                                *ngIf="saving"
                                                [disabled]="addingNew"
                                                pButton
                                                pRipple
                                                type="button"
                                                pInitEditableRow
                                                icon="pi pi-spin pi-spinner"
                                                class="p-button-rounded p-button-text"
                                            ></button>
                                            <button
                                                *ngIf="!editing && contact.corpoId && canUpdate"
                                                [disabled]="addingNew"
                                                [hidden]="saving"
                                                pButton
                                                pRipple
                                                type="button"
                                                pInitEditableRow
                                                icon="pi pi-pencil"
                                                (click)="onRowEditInit()"
                                                class="p-button-rounded p-button-text"
                                            ></button>
                                            <button
                                                *ngIf="!editing && contact.corpoId && canDelete"
                                                [disabled]="addingNew"
                                                [hidden]="saving"
                                                pButton
                                                pRipple
                                                type="button"
                                                icon="pi pi-trash"
                                                (click)="deleteNumeroUtile(contact)"
                                                class="p-button-rounded p-button-text"
                                            ></button>
                                            <button
                                                *ngIf="editing"
                                                pButton
                                                pRipple
                                                type="button"
                                                pSaveEditableRow
                                                icon="pi pi-check"
                                                (click)="onRowEditSave(contact)"
                                                class="p-button-rounded p-button-text p-button-success p-mr-2"
                                            ></button>
                                            <button
                                                *ngIf="editing"
                                                pButton
                                                pRipple
                                                type="button"
                                                pCancelEditableRow
                                                icon="pi pi-times"
                                                (click)="onRowEditCancel(contact)"
                                                class="p-button-rounded p-button-text p-button-danger"
                                            ></button>
                                        </span>
                                        <span *ngSwitchCase="'email'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="email" [(ngModel)]="contact.email" />
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
                                                </ng-template>
                                            </p-cellEditor>
                                        </span>
                                        <span *ngSwitchCase="'issiRadio'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="number" [(ngModel)]="contact.issiRadio" />
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ contact[col.field] }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </span>
                                        <span *ngSwitchDefault>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="contact[col.field]" />
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ contact[col.field] }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="columns.length">Nessun numero</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>

                <p-tabPanel header="Rubrica Corpo" *ngIf="enableViewCorpoList">
                    <div class="grid h-full">
                        <div class="col-7">
                            <p-orderList
                                [value]="rubrica"
                                [listStyle]="{ 'max-height': '30rem' }"
                                header="Componenti del corpo"
                                filterBy="cognome,nome,matricola,numero"
                                filterPlaceholder="Ricerca per nome, cognome, numero o matricola"
                                [dragdrop]="false"
                                (onSelectionChange)="onSelectionChange($event.value)"
                            >
                                <ng-template let-contact pTemplate="item">
                                    <div class="product-item">
                                        <div class="image-container">
                                            <img src="assets/images/logo.png" [alt]="contact.nome" class="product-image" />
                                        </div>
                                        <div class="product-list-detail">
                                            <h5 class="mb-2">{{ contact.cognome }} {{ contact.nome }}</h5>
                                            <i class="pi pi-tag product-category-icon"></i>
                                            <span class="product-category">{{ contact.matricola }}</span>
                                        </div>
                                        <div class="product-list-action">
                                            <h6 class="mb-2">{{ contact.numero }}</h6>
                                            <span class="product-badge">{{ contact.descrizione }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-orderList>
                        </div>

                        <div class="col-5">
                            <p-card header="{{ singleVigileNome }}">
                                <p-table [value]="singleVigile" styleClass="p-datatable-striped" responsiveLayout="scroll">
                                    <ng-template pTemplate="body" let-contactSingle>
                                        <tr>
                                            <td>{{ contactSingle.tipoRecapitoDescrizione }}</td>
                                            <td>{{ contactSingle.recapito }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-card>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
