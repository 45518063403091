import { Component, OnInit } from '@angular/core';

import { EMPTY, of } from 'rxjs';
import { NgxAuthService } from 'ngx-auth-utils';
import { catchError, switchMap } from 'rxjs/operators';

import { THEME_ICONS } from '@shared/faIcons';
import { TipoEntita } from '@shared/types/auth.models';
import { ToastService } from '@core/services/toast.service';
import { DropDownBoxChangeEvent } from '@shared/types/primeng.types';
import { OrganicoService, ScadenzeService } from '@core/http-gen/services';
import { ScadenzaVigileDto } from '@core/http-gen/models/scadenza-vigile-dto';
import { LocalStorageConstants } from '@shared/constants/local-storage.constants';
import { CorpoListItemDto, UnioneDto, UtenteProfiloDto } from '@core/http-gen/models';
import { TipoUtente } from '@modules/gestione-utenti/nuovo-utente-esterno/nuovo-utente-esterno.models';

interface ScadenzaCorpoWithDate {
    cognome?: string;
    idPersona?: string;
    matricola?: string;
    nome?: string;
    scadenzaPatente?: Date;
    scadenzaVisitaMedica?: Date;
    tipoPatente?: string;
}

@Component({
    selector: 'app-scadenze',
    templateUrl: './scadenze.component.html',
    styleUrls: ['./scadenze.component.scss'],
})
export class ScadenzeComponent implements OnInit {
    icons = THEME_ICONS;
    scadenzeCorpo: Array<ScadenzaCorpoWithDate> = [];
    scadenzeVigile: Array<ScadenzaVigileDto> = [];
    enableViewCorpoList = true; //se falsa nasconde il contenuto di corpo list e ti riconduce all'indece della seconda tab
    showCorpoUnioneSelector = false;
    private _tipoEntita: TipoEntita | null =
        localStorage.getItem(LocalStorageConstants.tipoEntitaHeader) != null
            ? <TipoEntita>+(<string>localStorage.getItem(LocalStorageConstants.tipoEntitaHeader))
            : null;
    unioneList: Array<UnioneDto> = [];
    selectedUnione: number | null = null;
    corpoList: Array<CorpoListItemDto> = [];
    selectedCorpo: number | null = null;
    today = new Date();
    in60Days = new Date(new Date().setDate(new Date().getDate() + 60));

    //----------------------------tabella scadenza corpo-------------------------------------//

    /*Questi campi field devono coincidere con lenum che mi ritorna la chiamata(api) */
    columnsCorpo = [
        { field: 'matricola', header: 'Matricola' },
        { field: 'nome', header: 'Nome' },
        { field: 'cognome', header: 'Cognome' },
        { field: 'scadenzaVisitaMedica', header: 'Scadenza Visita Medica' },
        { field: 'scadenzaPatente', header: 'Scadenza Patente' },
        { field: 'tipoPatente', header: 'Tipo di Patente' },
    ];
    loadingCorpoList = true;
    loadingVigileList = true;
    fields: string[] = [];
    //----------------------------tabella scadenza vigile-------------------------------------//
    /*Questi campi field devono coincidere con lenum che mi ritorna la chiamata(api) */
    columnsVigile = [
        { field: 'scadenzaVisitaMedica', header: 'Scadenza Visita Medica' },
        { field: 'scadenzaPatente', header: 'Scadenza Patente' },
        { field: 'tipoPatente', header: 'Tipo di Patente' },
    ];

    //----------------------------------------------------------------------------------------//
    constructor(
        private scadenzeService: ScadenzeService,
        private organicoService: OrganicoService,
        private ngxAuthService: NgxAuthService,
        private toastService: ToastService
    ) {}

    ngOnInit(): void {
        // Un utente può vedere il selettore di unione o corpo se è un amministratore, un utente federazione o unione
        this.showCorpoUnioneSelector =
            this._tipoEntita === TipoEntita.Federazione ||
            this._tipoEntita === TipoEntita.Unione ||
            (<UtenteProfiloDto>this.ngxAuthService.snapshot.user).tokenData?.tipoUtente === TipoUtente.Administrator;

        this.fields = this.columnsCorpo.map((col) => col.field);
        this.loadScadenzeVigile();

        //----------------si puo implementare in base alla tipologia di utenza---------------//
        //--------------- loggata se vedere o meno la lista del corpo------------------------//
        if (this.enableViewCorpoList) {
            this.loadSelectBoxAndScadenzeCorpo();
        }
    }

    loadSelectBoxAndScadenzeCorpo(unioneId?: number): void {
        this.loadingCorpoList = true;
        if (this.showCorpoUnioneSelector) {
            this.organicoService
                .apiOrganicoUnioneListGet()
                .pipe(
                    switchMap((unioneList: Array<UnioneDto>) => {
                        if (this._tipoEntita === TipoEntita.Unione) {
                            // Se l'utente è un ispettore filtro solamente la sua unione
                            const idEntita: number | null =
                                localStorage.getItem(LocalStorageConstants.idEntitaHeader) != null
                                    ? <TipoEntita>+(<string>localStorage.getItem(LocalStorageConstants.idEntitaHeader))
                                    : null;
                            unioneList = unioneList.filter((unione) => unione.idUnione == idEntita);
                            this.unioneList = unioneList;
                        } else {
                            this.unioneList = unioneList;
                        }
                        if (unioneId) {
                            this.selectedUnione = unioneId;
                        } else {
                            this.selectedUnione = unioneList[0].idUnione;
                        }

                        return of(this.selectedUnione);
                    }),
                    switchMap((unioneId: number) => this.organicoService.apiOrganicoCorpoListGet({ unioneId: unioneId })),
                    switchMap((corpoUnioneList: Array<CorpoListItemDto>) => {
                        this.selectedCorpo = corpoUnioneList[0].idCorpo;
                        this.corpoList = corpoUnioneList;

                        return of(this.selectedCorpo);
                    }),
                    switchMap((selectedCorpoId: number) => {
                        this.loadScadenzeCorpo(selectedCorpoId);
                        return of(true);
                    })
                )
                .subscribe();
        } else {
            this.loadScadenzeCorpo(undefined);
        }
    }

    onUnioneChange(event: DropDownBoxChangeEvent<number>): void {
        this.selectedUnione = event.value;
        this.loadSelectBoxAndScadenzeCorpo(this.selectedUnione);
    }

    onCorpoChange(event: DropDownBoxChangeEvent<number>): void {
        this.selectedCorpo = event.value;
        this.loadScadenzeCorpo(this.selectedCorpo);
    }

    private loadScadenzeCorpo(corpoId: number | undefined): void {
        this.loadingCorpoList = true;
        this.scadenzeService
            .apiScadenzeScadenzeCorpoListGet({ corpoId: corpoId })
            .pipe(
                catchError(() => {
                    this.toastService.error('Errore nel caricamento delle scadenze');
                    return EMPTY;
                })
            )
            .subscribe((scadenzeCorpo) => {
                this.scadenzeCorpo = scadenzeCorpo.map((scadenza) => {
                    return {
                        ...scadenza,
                        scadenzaVisitaMedica: scadenza.scadenzaVisitaMedica ? new Date(scadenza.scadenzaVisitaMedica) : undefined,
                        scadenzaPatente: scadenza.scadenzaPatente ? new Date(scadenza.scadenzaPatente) : undefined,
                    };
                });
                this.loadingCorpoList = false;
            });
    }

    loadScadenzeVigile(): void {
        this.loadingVigileList = true;
        this.scadenzeService
            .apiScadenzeScadenzeVigileListGet()
            .pipe(
                catchError(() => {
                    this.toastService.error('Errore nel caricamento delle scadenze');
                    return EMPTY;
                })
            )
            .subscribe((scadenzeVigile) => {
                this.scadenzeVigile = scadenzeVigile;
                this.loadingVigileList = false;
            });
    }
}
