export class ApplicationException extends Error {
    public applicationExceptionCode: ApplicationExceptionCode;
    public message: string;
    public data: unknown;

    constructor(applicationExceptionCode: ApplicationExceptionCode, message: string, data?: unknown) {
        super(message);

        this.applicationExceptionCode = applicationExceptionCode;
        this.message = message;
        this.data = data;
    }
}

export enum ApplicationExceptionCode {
    Database = 1,
    Authorization = 2,
    Validation = 3,
    Skebby = 4,
    Forbidden = 5,
    Tetra = 6,

    UtenteGiaEsistente = 20,
    UtenteNonEsistente = 21,
    UtentePasswordErrata = 22,
    UtenteCodiceDueFattoriNonEsistente = 23,
    UtenteErroreRegistrazione = 24,
    UtenteNessunCorpoAssociato = 25,
    UtenteNessunNumeroTelefonoTrovato = 26,
    UtenteEsternoCorpiMultipliAssociati = 27,
    UtenteEsternoNessunCorpoAssociato = 28,
    UtenteModificaPasswordNonConsentita = 29,
    UtenteResetPasswordGiaUtilizzata = 30,

    MatricolaNonValida = 40,
    TooManySessions = 42,
    RuoloNonEsistente = 50,

    OTPUtenteNonEsistente = 60,
    OTPUtenteNoDispositivoMobile = 61,
    OTPNessunaRichiestaUtente = 62,

    SelettivaNonVisibile = 70,
}

export class ValidationResult {
    constructor(public validationField: string, public validationConstraint: string, public validationMessage: string) {}
}
