<div class="container">
    <p-card styleClass="invio-notifiche-container">
        <!-- <pre>{{selectedNodes | json}}</pre> -->
        <div class="grid h-full">
            <div class="col-5">
                <div class="grid h-full">
                    <div class="col">
                        <p-accordion>
                            <p-accordionTab header="Gradi" [selected]="true">
                                <p-treeTable
                                    [value]="gradoTreeList"
                                    selectionMode="checkbox"
                                    (onNodeExpand)="loadGradoNode($event)"
                                    (onNodeSelect)="nodeSelect($event)"
                                    [(selection)]="selectedGradoNodes"
                                    (onNodeUnselect)="nodeUnselect($event)"
                                >
                                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                        <div class="grid" *ngIf="rowData.tipoSelezioneGrado === tipoSelezioneGrado.grado">
                                            <div class="col-9 td-descrizione">
                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
                                                <div class="sel-descrizione">
                                                    {{ rowData.grado.descrizione }}
                                                </div>
                                            </div>
                                            <div class="col-3 text-right">
                                                {{ rowData.grado.count }}
                                            </div>
                                        </div>
                                        <div class="grid" *ngIf="rowData.tipoSelezioneGrado === tipoSelezioneGrado.volontario">
                                            <div class="col sel-interno">
                                                <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
                                                {{ rowData.volontario.cognomePersona }} {{ rowData.volontario.nomePersona }}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-treeTable>
                                <i *ngIf="loadingGradi" class="pi pi-spin pi-spinner fs-23"></i>
                            </p-accordionTab>
                            <p-accordionTab header="Mansioni">
                                <p-treeTable
                                    [value]="mansioneTreeList"
                                    selectionMode="checkbox"
                                    (onNodeExpand)="loadMansioneNode($event)"
                                    (onNodeSelect)="nodeSelect($event)"
                                    [(selection)]="selectedMansioneNodes"
                                    (onNodeUnselect)="nodeUnselect($event)"
                                >
                                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                        <div class="grid" *ngIf="rowData.tipoSelezioneMansione === tipoSelezioneMansione.mansione">
                                            <div class="col-9">
                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
                                                <div class="sel-descrizione">
                                                    {{ rowData.mansione.descrizione }}
                                                </div>
                                            </div>
                                            <div class="col-3 text-right">
                                                {{ rowData.mansione.count }}
                                            </div>
                                        </div>
                                        <div class="grid" *ngIf="rowData.tipoSelezioneMansione === tipoSelezioneMansione.volontario">
                                            <div class="col sel-interno">
                                                <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
                                                {{ rowData.volontario.cognomePersona }} {{ rowData.volontario.nomePersona }}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-treeTable>
                                <i *ngIf="loadingMansioni" class="pi pi-spin pi-spinner"></i>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <p-card>
                    <form [formGroup]="invioNotificaForm" novalidate>
                        <div class="p-field">
                            <label for="titolo">Titolo</label>
                            <input id="titolo" type="text" pInputText formControlName="titolo" />
                        </div>
                        <div class="p-field">
                            <label for="messaggio">Messaggio*</label>
                            <textarea id="messaggio" [rows]="5" pInputTextarea formControlName="messaggio"></textarea>
                            <small id="messaggio-help"
                                >La notifica mostrerà solamente i primi X caratteri, l'utente potra visualizzare l'intera notifica entrando
                                nell'app</small
                            >
                        </div>
                        <div>
                            <p-fileUpload #allegatiUploader [multiple]="true" [showUploadButton]="false"></p-fileUpload>
                        </div>
                    </form>
                    <div class="grid">
                        <div class="col text-right">
                            <button
                                pButton
                                type="button"
                                label="Invia"
                                (click)="inviaNotifica()"
                                [disabled]="invioNotificaForm.invalid"
                            ></button>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </p-card>
</div>
