import { Inject, Injectable, InjectionToken } from '@angular/core';

export const STORAGE = new InjectionToken<Storage>('Storage');

export class StorageService implements Storage {
    get length(): number {
        return this.storage.length;
    }

    constructor(@Inject(STORAGE) private storage: Storage) {}

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }
}

export const LOCAL_STORAGE = new InjectionToken<Storage>('Local Storage', {
    providedIn: 'root',
    factory: (): Storage => localStorage,
});

@Injectable({
    providedIn: 'root',
    deps: [LOCAL_STORAGE],
})
export class LocalStorageService extends StorageService {
    constructor(@Inject(LOCAL_STORAGE) storage: Storage) {
        super(storage);
    }
}

export const SESSION_STORAGE = new InjectionToken<Storage>('Session Storage', {
    providedIn: 'root',
    factory: (): Storage => sessionStorage,
});

@Injectable({
    providedIn: 'root',
    deps: [SESSION_STORAGE],
})
export class SessionStorageService extends StorageService {
    constructor(@Inject(SESSION_STORAGE) storage: Storage) {
        super(storage);
    }
}
