/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { allDelete } from '../fn/operations/all-delete';
import { AllDelete$Params } from '../fn/operations/all-delete';
import { allGet } from '../fn/operations/all-get';
import { AllGet$Params } from '../fn/operations/all-get';
import { countGet } from '../fn/operations/count-get';
import { CountGet$Params } from '../fn/operations/count-get';
import { orGet } from '../fn/operations/or-get';
import { OrGet$Params } from '../fn/operations/or-get';
import { theGet } from '../fn/operations/the-get';
import { TheGet$Params } from '../fn/operations/the-get';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `allGet()` */
    static readonly AllGetPath = 'all';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `allGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    allGet$Response(params?: AllGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return allGet(this.http, this.rootUrl, params, context);
    }

    /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `allGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    allGet(params?: AllGet$Params, context?: HttpContext): Observable<void> {
        return this.allGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `allDelete()` */
    static readonly AllDeletePath = 'all';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `allDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    allDelete$Response(params?: AllDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return allDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `allDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    allDelete(params?: AllDelete$Params, context?: HttpContext): Observable<void> {
        return this.allDelete$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `countGet()` */
    static readonly CountGetPath = 'count';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `countGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    countGet$Response(params?: CountGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return countGet(this.http, this.rootUrl, params, context);
    }

    /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `countGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    countGet(params?: CountGet$Params, context?: HttpContext): Observable<void> {
        return this.countGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `theGet()` */
    static readonly TheGetPath = 'the';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `theGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    theGet$Response(params?: TheGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return theGet(this.http, this.rootUrl, params, context);
    }

    /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `theGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    theGet(params?: TheGet$Params, context?: HttpContext): Observable<void> {
        return this.theGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `orGet()` */
    static readonly OrGetPath = 'or';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `orGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    orGet$Response(params?: OrGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return orGet(this.http, this.rootUrl, params, context);
    }

    /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `orGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    orGet(params?: OrGet$Params, context?: HttpContext): Observable<void> {
        return this.orGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }
}
