import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { AlertcallsService } from '@core/http-gen/services';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { NgxAuthService } from 'ngx-auth-utils';

// Models
import { AlertCall } from '@core/models/entry.model';
import { Permesso, PermessoValore } from '@shared/types/auth.models';

@Component({
    selector: 'app-alertcalls',
    templateUrl: './alertcalls.component.html',
    styleUrls: ['./alertcalls.component.scss'],
})
export class AlertcallsComponent implements OnInit {
    public permissionToViewAlertCalls = false;

    public lastUpdateTime = new Date();

    public alertCallsLength = 0;
    public alertCallsList: AlertCall[] = [];
    public alertCallsLoading = true;

    public itemsPerPage = 25;
    public currentPage = 1;

    public paginationPageNumbersToDisplay = [1, 2, 3, 4, 5, 6, 7];
    public paginationActionToDisplay = {
        prev: false,
        next: true,
    };

    constructor(
        private alertcallsService: AlertcallsService,
        private router: Router,
        private permissionChecker: PermissionCheckerService,
        private authService: NgxAuthService
    ) {
        this.checkPermission();
    }

    private checkPermission(): void {
        // Set the permission to view the alert calls
        this.permissionToViewAlertCalls = this.permissionChecker.hasThePermission(
            Permesso.StoricoSelettive,
            PermessoValore.Lettura,
            this.authService.snapshot.user.tokenData.permessoList
        );

        // If the user has no permission, redirect to the home page
        if (!this.permissionToViewAlertCalls) {
            this.router.navigate(['/']);
        }
    }

    public ngOnInit(): void {
        this.currentPage = this.getPageFromUrl();
        if (this.currentPage < 1) {
            this.currentPage = 1;
        }

        this.getCountAlertCalls();
        this.getAlertCalls(this.currentPage);
    }

    private getPageFromUrl(): number {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        return page ? parseInt(page, 10) : 1;
    }

    private getCountAlertCalls(): void {
        this.alertcallsService.apiAlertcallsCountGet().subscribe((data) => {
            this.alertCallsLength = data.results as number;
        });
    }

    public getAlertCalls(page = 1): void {
        this.alertCallsLoading = true;
        this.alertcallsService
            .apiAlertcallsGet({
                query: JSON.stringify({
                    orderBy: {
                        dataOra: 'DESC',
                    },
                    select: ['id', 'dataOra', 'eventoTipologia', 'eventoDettaglio', 'eventoId', 'corpoId'],
                    take: this.itemsPerPage,
                    skip: this.itemsPerPage * (page - 1),
                }),
                populateCorpo: true,
            })
            .subscribe((data) => {
                this.alertCallsList = (data.data as AlertCall[]).slice(0, this.itemsPerPage);
                this.alertCallsLoading = false;
                this.lastUpdateTime = new Date();

                // Scroll to top
                window.scrollTo(0, 0);
            });
    }
}
