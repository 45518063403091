import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ApplicationException, ApplicationExceptionCode, ValidationResult } from '@shared/types/api-exception.models';
import { MessageService } from 'primeng/api';

@Injectable()
export class AppExceptionHandler implements ErrorHandler {
    constructor(private messageService: MessageService, private zone: NgZone) {}

    handleError(error: unknown): void {
        if (error instanceof HttpErrorResponse) {
            const httpErrorResponse: HttpErrorResponse = error;

            if (httpErrorResponse.status === 403 || httpErrorResponse.status === 400) {
                // Se l'errore è 403 significa che è un eccezione gestita
                const applicationException: ApplicationException = httpErrorResponse.error;

                if (applicationException.applicationExceptionCode !== ApplicationExceptionCode.Validation) {
                    this.zone.run(() =>
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Errore',
                            detail: applicationException.message,
                        })
                    );
                } else if (applicationException.applicationExceptionCode === ApplicationExceptionCode.Validation) {
                    const validationResultList: Array<ValidationResult> = <Array<ValidationResult>>applicationException.data;
                    validationResultList.forEach((validationResult: ValidationResult) => {
                        this.zone.run(() =>
                            this.messageService.add({
                                severity: 'warn',
                                summary: validationResult.validationField,
                                detail: validationResult.validationMessage,
                            })
                        );
                    });
                }
            } else {
                console.error('AN ERROR OCCURRED: ', error);
                this.zone.run(() =>
                    this.messageService.add({
                        severity: 'error',
                        summary: `L'operazione potrebbe non essere andata a buon fine`,
                        detail: 'Errore imprevisto',
                    })
                );
            }
        } else {
            console.error('AN ERROR OCCURRED: ', error);
            this.zone.run(() =>
                this.messageService.add({
                    severity: 'error',
                    summary: `L'operazione potrebbe non essere andata a buon fine`,
                    detail: 'Errore imprevisto',
                })
            );
        }
    }
}
