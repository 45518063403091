import { Pipe, PipeTransform } from '@angular/core';
import { UtenteProfiloDto } from '@core/http-gen/models/utente-profilo-dto';

@Pipe({
    name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
    transform(value?: UtenteProfiloDto): unknown {
        if (!value) {
            return '--';
        }

        // Name or surname
        if (value.nome && value.cognome) {
            return (value.nome[0] + value.cognome[0]).toUpperCase();
        }
        if (value.nome) {
            return (value.nome[0] + value.nome[1]).toUpperCase();
        }
        if (value.cognome) {
            return (value.cognome[0] + value.cognome[1]).toUpperCase();
        }

        // Username
        if (value.nomeUtente) {
            return (value.nomeUtente[0] + value.nomeUtente[1]).toUpperCase();
        }

        return '--';
    }
}
