/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DispositivoMobileConfigDto } from '../../models/dispositivo-mobile-config-dto';
import { DispositivoMobileGetConfigDto } from '../../models/dispositivo-mobile-get-config-dto';

export interface ApiDispositivoMobileGetConfigPost$Params {
    body: DispositivoMobileGetConfigDto;
}

export function apiDispositivoMobileGetConfigPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiDispositivoMobileGetConfigPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<DispositivoMobileConfigDto>> {
    const rb = new RequestBuilder(rootUrl, apiDispositivoMobileGetConfigPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<DispositivoMobileConfigDto>;
        })
    );
}

apiDispositivoMobileGetConfigPost.PATH = '/api/dispositivo-mobile/get-config';
