/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VvfVolontarioGradoDto } from '../../models/vvf-volontario-grado-dto';

export interface ApiOrganicoVolontarioListByGradoGet$Params {
    /**
     * Id del grado per cui caricare i vigili
     */
    gradoId: number;

    /**
     * Id dell'unione per cui caricare i volontari
     */
    unioneId?: number;

    /**
     * Id del corpo per cui caricare i volontari
     */
    corpoId?: number;
}

export function apiOrganicoVolontarioListByGradoGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiOrganicoVolontarioListByGradoGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<VvfVolontarioGradoDto>>> {
    const rb = new RequestBuilder(rootUrl, apiOrganicoVolontarioListByGradoGet.PATH, 'get');
    if (params) {
        rb.query('gradoId', params.gradoId, {});
        rb.query('unioneId', params.unioneId, {});
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<VvfVolontarioGradoDto>>;
        })
    );
}

apiOrganicoVolontarioListByGradoGet.PATH = '/api/organico/volontario-list-by-grado';
