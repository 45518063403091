import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MonitorService {
    constructor() {}

    /** This function checks if the service is online by checking the frontend and backend URLs */
    public async isServiceOnline(): Promise<boolean> {
        // Get the current URL
        const frontendUrl = window.location.href;
        const backendUrl = 'api/health';

        // try to fetch the frontend URL
        const promises = await Promise.all([this.fetchOnline(frontendUrl), this.fetchOnline(backendUrl)]);
        return promises.every((status) => status);
    }

    /** This function checks if the service is online by checking the URL */
    private async fetchOnline(url: string): Promise<boolean> {
        try {
            const response = await fetch(url);

            // The response can be a range of 200-299 or 400-499
            if ((response.status >= 200 && response.status <= 299) || (response.status >= 400 && response.status <= 499)) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error fetching URL:', url, error);
            return false;
        }
    }

    /**
     * This function schedules a refresh of the page.
     * If the minutes are not provided it will refresh within 120-180 minutes to avoid all clients refreshing at the same time.
     */
    public scheduleRefresh(minutes: number | undefined = undefined): void {
        if (!minutes) {
            // Random time between 120 and 180 minutes, to avoid all the clients to refresh at the same time (if the service is down)
            minutes = Math.floor(Math.random() * (180 - 120 + 1)) + 120;
        }

        window.console.log('Refreshing in', minutes, 'minutes');

        setInterval(
            async () => {
                const status = await this.isServiceOnline();
                if (status) {
                    window.location.reload();
                } else {
                    window.console.error('Service is offline, will retry in 1 minute');
                    this.scheduleRefresh(1);
                }
            },
            minutes * 60 * 1000,
        );
    }
}
