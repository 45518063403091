import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { OrganicoService } from '@core/http-gen/services';
import { GradoCountDto, GradoTokenDto, TipoEntita } from '@core/http-gen/models';
import { LocalStorageConstants } from '@shared/constants/local-storage.constants';

import { GradoItem } from './header.models';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private _gradoList$: BehaviorSubject<Array<GradoItem>> = new BehaviorSubject([] as Array<GradoItem>);

    public gradoList$: Observable<Array<GradoItem>> = this._gradoList$.asObservable();
    constructor(private _organicoService: OrganicoService) {}

    /**
     * Imposto i gradi letti dal profilo nel servizce che li mantiene per la visualizzazione nella pagina.
     * Se non c'è un grado selezionato imposto l'ultimo della lista
     */
    public setGradoList(gradoTokenList: Array<GradoTokenDto>): void {
        const gradoCreateList: Array<GradoItem> = [];

        let idGradoSelected: number | null =
            localStorage.getItem(LocalStorageConstants.idGradoHeader) != null
                ? +(<string>localStorage.getItem(LocalStorageConstants.idGradoHeader))
                : null;
        let tipoEntitaSelected: TipoEntita | null =
            localStorage.getItem(LocalStorageConstants.tipoEntitaHeader) != null
                ? <TipoEntita>+(<string>localStorage.getItem(LocalStorageConstants.tipoEntitaHeader))
                : null;
        let idEntitaSelected: number | null =
            localStorage.getItem(LocalStorageConstants.idEntitaHeader) != null
                ? +(<string>localStorage.getItem(LocalStorageConstants.idEntitaHeader))
                : null;

        if (idGradoSelected === null) {
            // Se non è stato ancora selezionato un grado, seleziono di default il primo
            idGradoSelected = gradoTokenList[gradoTokenList.length - 1].i;
            tipoEntitaSelected = gradoTokenList[gradoTokenList.length - 1].t;
            idEntitaSelected = gradoTokenList[gradoTokenList.length - 1].e;

            localStorage.setItem(LocalStorageConstants.idGradoHeader, idGradoSelected.toString());
            localStorage.setItem(LocalStorageConstants.tipoEntitaHeader, (+tipoEntitaSelected).toString());
            localStorage.setItem(LocalStorageConstants.idEntitaHeader, idEntitaSelected.toString());
        }

        // Leggo la lista dei gradi per ricavare il nome da visualizzare all'utente
        this._organicoService.apiOrganicoGradoListGet({ countVigili: false }).subscribe((gradoList: Array<GradoCountDto>) => {
            gradoTokenList.forEach((gradoToken: GradoTokenDto) => {
                const gradoDto: GradoCountDto | undefined = gradoList.find((grado: GradoCountDto) => grado.idGrado == gradoToken.i);

                if (gradoDto != null) {
                    gradoCreateList.push({
                        descrizioneGrado: gradoDto.descrizione,
                        selected: gradoDto.idGrado === idGradoSelected,
                        idEntita: gradoToken.e,
                        idGrado: gradoToken.i,
                        orderGrado: gradoToken.o,
                        tipoEntita: gradoToken.t,
                    } as GradoItem);
                }
            });

            this._gradoList$.next(gradoCreateList);
        });
    }

    /** Ripulisco la lista dei gradi selezionati */
    public cleanGradoList(): void {
        this._gradoList$.next([]);
    }
}
