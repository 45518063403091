// Model
import { User } from '@core/models/entry.model';

export function getFullName(user: User | undefined): string {
    if (!user) {
        return '';
    }

    if (user.name && user.surname) {
        return firstUpperCase(`${user.name} ${user.surname}`);
    }

    if (user.name) {
        return firstUpperCase(user.name);
    }

    if (user.surname) {
        return firstUpperCase(user.surname);
    }

    return user.nomeUtente;
}

function firstUpperCase(str: string): string {
    const strings = str.split(' ');

    return strings.map((string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).join(' ');
}
