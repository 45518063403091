import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelettivaService } from '@core/http-gen/services/selettiva.service';
import { DispositivoMobileService } from '@core/http-gen/services/dispositivo-mobile.service';
import { SelettivaListItemDto } from '@core/http-gen/models/selettiva-list-item-dto';
import { LazyLoadEvent } from 'primeng/api';
import { ONE_HOUR, ONE_MINUTE, ONE_SECOND } from '@shared/utils/time.utils';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@env';
import { ActivatedRoute } from '@angular/router';
import {} from '@core/http-gen/models';

interface SelettivaListCentraleItemDto extends SelettivaListItemDto {
    vigiliInArrivo?: number;
    autistiInArrivo?: number;
    nonInArrivo?: number;
    notificheInviate?: number;
    notificheRicevute?: number;
    gravitaEvento?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-monitor-centrale',
    templateUrl: './monitor-centrale.component.html',
    styleUrls: ['./monitor-centrale.component.scss'],
})
export class MonitorCentraleComponent implements OnInit {
    public loading = true;
    private mouseMoveTimeout: number | undefined;
    private intervalUpdateSelettive: number | undefined;
    public clock: string | undefined = this.clockTime();
    public selettivaList: Array<SelettivaListCentraleItemDto> = [];
    public viewAnswers = false;

    constructor(
        private selettivaService: SelettivaService,
        private dispositivoService: DispositivoMobileService,
        private activateRoute: ActivatedRoute
    ) {}

    private getParams(): void {
        this.activateRoute.queryParams.subscribe((params) => {
            this.viewAnswers = params.viewAnswers === 'true';
        });
    }

    ngOnInit(): void {
        // Get url params
        this.getParams();

        // Start clock
        this.startClockTime();

        // Set refresh every 2 hours
        this.scheduleRefresh(2);

        // Request permission for notifications
        this.requestPermission();

        // Listen for notifications
        this.listenForNotifications();

        // Start polling
        this.polling();

        // Hide mouse cursor after 5 seconds of inactivity
        document.body.addEventListener('mousemove', () => {
            document.body.style.cursor = 'default';
            clearTimeout(this.mouseMoveTimeout);
            this.mouseMoveTimeout = window.setTimeout(() => {
                document.body.style.cursor = 'none';
            }, 3000);
        });
    }

    public startClockTime(): void {
        let lastMinute = new Date().getMinutes();

        setInterval(() => {
            const now = new Date();
            if (now.getMinutes() == lastMinute) {
                return;
            }

            lastMinute = now.getMinutes();
            this.clock = this.clockTime();
        }, 1000);
    }

    private clockTime(): string {
        const str = new Date().toLocaleTimeString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });

        return str.replace(',', ' - ');
    }

    private getGravita(codice?: string): string | undefined {
        if (!codice) {
            return undefined;
        }
        const regex = new RegExp('[a-zA-Z]');
        const result = regex.exec(codice);

        return result ? result[0] : undefined;
    }

    public caricaElencoSelettive(event: LazyLoadEvent): void {
        this._loadElencoSelettive(event.rows, event.first).subscribe();
    }

    /** Metodo interno che carica la lista delle selettive */
    private _loadElencoSelettive(take?: number, skip?: number): Observable<void> {
        this.loading = true;

        // wait 1 second before loading data
        return this.selettivaService
            .apiSelettivaSelettivaListGet({ take: take, skip: skip, populateAnswers: this.viewAnswers, populateVigiliAllertati: true })
            .pipe(
                map((selettivaList: Array<SelettivaListItemDto>) => {
                    this.selettivaList = selettivaList;

                    this.selettivaList.map((selettiva) => {
                        selettiva.gravitaEvento = this.getGravita(selettiva.eventoCodice);
                        selettiva.vigiliAllertati = selettiva.vigiliAllertati || 0;
                        selettiva.inArrivo = selettiva.inArrivo || 0;
                        selettiva.nonInArrivo = selettiva.nonInArrivo || 0;
                    });

                    this.loading = false;
                })
            );
    }

    public isNewAlertCall(selettiva: SelettivaListCentraleItemDto): boolean {
        return new Date().getTime() - new Date(selettiva.dataOra).getTime() <= 2 * ONE_MINUTE;
    }

    public eventInProgress(selettiva: SelettivaListCentraleItemDto): boolean {
        // TODO: implement logic, now return true in the first 15 minutes
        return new Date().getTime() - new Date(selettiva.dataOra).getTime() <= 10 * ONE_MINUTE;
    }

    private scheduleRefresh(hours: number): void {
        const timeUntilRefresh = ONE_HOUR * hours;

        window.console.log(`Scheduled refresh in ${hours} hours`);

        setTimeout(function () {
            location.reload();
        }, timeUntilRefresh);
    }

    private requestPermission(): void {
        const messaging = getMessaging();

        getToken(messaging, { vapidKey: environment.firebase.vapidKey })
            .then((currentToken) => {
                if (currentToken) {
                    const oldToken = localStorage.getItem('tokenDispositivoMobile');
                    let deviceUid = localStorage.getItem('deviceUid');
                    if (!deviceUid) {
                        deviceUid = uuidv4();
                        localStorage.setItem('deviceUid', deviceUid);
                    }

                    if (oldToken !== currentToken) {
                        localStorage.setItem('tokenDispositivoMobile', currentToken);
                        this.dispositivoService
                            .apiDispositivoMobileInsertOrUpdatePost({
                                body: {
                                    deviceUId: deviceUid,
                                    newToken: currentToken,
                                    tipoDispositivo: 3,
                                    tipoUpdate: 3,
                                },
                            })
                            .subscribe();
                    }
                } else {
                    // Show permission request UI
                    window.console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            })
            .catch((err) => {
                window.console.log('An error occurred while retrieving token. ', err);
                // ...
            });
    }

    private listenForNotifications(): void {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            if (payload.data?.tipoPushNotification === '99' || payload.data?.tipoPushNotification === '98') {
                this.polling();
            }
        });
    }

    /** Metodo che si occupa di fare il polling delle selettive */
    private async polling(): Promise<void> {
        this._loadElencoSelettive(14, 0).subscribe(() => {
            // Check if last selected selettiva is still active
            const delta = new Date().getTime() - new Date(this.selettivaList[0].dataOra).getTime();

            if (delta <= 15 * ONE_MINUTE) {
                this.nextPolling(30 * ONE_SECOND);
                return;
            }

            this.clearPolling();
        });
    }

    private nextPolling(time: number): void {
        if (this.intervalUpdateSelettive) {
            clearTimeout(this.intervalUpdateSelettive);
        }

        window.setTimeout(() => {
            this.polling();
        }, time);
    }

    private clearPolling(): void {
        if (this.intervalUpdateSelettive) {
            clearTimeout(this.intervalUpdateSelettive);
        }
    }
}
