import { Component, OnInit } from '@angular/core';

// Services
import { SessionService } from '@core/http-gen/services/session.service';
import { AlertcallsService } from '@core/http-gen/services';
import { PushNotificationService } from '@core/http-gen/services/push-notification.service';

// Models
import { Session, AlertCall, PushNotification, User } from '@core/models/entry.model';

// Utils
import { getSessionType, getUserType, getPushNotificationType } from '@core/utils/enum-to-value.utils';
import { getFullName } from '@shared/utils/users.utils';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
    public lastUpdateTime = new Date();

    public usersOnlineList: Session[] = [];
    public usersOnlineLoading = true;

    public alertCallsList: AlertCall[] = [];
    public alertCallsLoading = true;

    public pushNotificationsList: PushNotification[] = [];
    public pushNotificationsLoading = true;

    constructor(
        private sessionService: SessionService,
        private alertcallsService: AlertcallsService,
        private pushNotificationService: PushNotificationService
    ) {}

    public ngOnInit(): void {
        this.getSession();
        this.getAlertCalls();
        this.getPushNotifications();
    }

    private getSession(): void {
        this.sessionService
            .apiSessionsGet({
                query: JSON.stringify({
                    orderBy: {
                        lastTimeUsedAt: 'DESC',
                    },
                    take: 8,
                    skip: 0,
                }),
                populateUser: true,
            })
            .subscribe((data) => {
                this.usersOnlineList = data.data as Session[];
                this.usersOnlineLoading = false;
            });
    }

    private getAlertCalls(): void {
        this.alertcallsService
            .apiAlertcallsGet({
                query: JSON.stringify({
                    orderBy: {
                        dataOra: 'DESC',
                    },
                    take: 8,
                    skip: 0,
                    select: ['id', 'dataOra', 'eventoTipologia', 'eventoDettaglio', 'eventoId', 'corpoId'],
                }),
                populateCorpo: true,
            })
            .subscribe((data) => {
                this.alertCallsList = data.data as AlertCall[];
                this.alertCallsLoading = false;
            });
    }

    private getPushNotifications(): void {
        this.pushNotificationService
            .apiPushnotificationsGet({
                query: JSON.stringify({
                    orderBy: {
                        createdAt: 'DESC',
                    },
                    take: 8,
                    skip: 0,
                }),
                populateUser: true,
            })
            .subscribe((data) => {
                this.pushNotificationsList = data.data as PushNotification[];
                this.pushNotificationsLoading = false;
            });
    }

    public getUserType(type: unknown): string {
        return getUserType(type as number);
    }

    public getSessionType(type: number): string {
        return getSessionType(type);
    }

    public getPushNotificationType(type: number): string {
        return getPushNotificationType(type);
    }

    public getFullName(user: User | undefined): string {
        return getFullName(user);
    }
}
