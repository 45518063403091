/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PermessoUtenteModificaPostDto } from '../../models/permesso-utente-modifica-post-dto';

export interface ApiAuthPermessoUtenteModificaPost$Params {
    /**
     * Id dell'utente per cui modificare i permessi
     */
    utenteId: number;

    /**
     * Id del corpo per cui modificare i permessi dell'utente
     */
    corpoId?: number;

    /**
     * Lista dei permessi associati ad un utente
     */
    body: Array<PermessoUtenteModificaPostDto>;
}

export function apiAuthPermessoUtenteModificaPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthPermessoUtenteModificaPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiAuthPermessoUtenteModificaPost.PATH, 'post');
    if (params) {
        rb.query('utenteId', params.utenteId, {});
        rb.query('corpoId', params.corpoId, {});
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiAuthPermessoUtenteModificaPost.PATH = '/api/auth/permesso-utente-modifica';
