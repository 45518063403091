/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RuoloPermessoEditDto } from '../../models/ruolo-permesso-edit-dto';

export interface ApiAuthUpdateRuoloPermessoListPost$Params {
    /**
     * Lista di permessi associati ad un ruolo
     */
    body: Array<RuoloPermessoEditDto>;
}

export function apiAuthUpdateRuoloPermessoListPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthUpdateRuoloPermessoListPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiAuthUpdateRuoloPermessoListPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiAuthUpdateRuoloPermessoListPost.PATH = '/api/auth/update-ruolo-permesso-list';
