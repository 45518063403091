export enum TipoUtente {
    PortaleFederazione = 1,
    MonitorSelettive = 2,
    TabletCorpo = 3,
    CellulareCorpo = 4,

    Administrator = 10,
    CorpoPermanente = 11,
    UtenteEsterno = 12,
}

export interface TipoUtenteDropDown {
    label: string;
    value: TipoUtente;
}
