export const globalDialogConfig = {
    width: '760px',
    modal: true,
    showHeader: true,
    closeOnEscape: true,
    contentStyle: { overflowY: 'auto', 'min-height': '70vh' },
};

export const confirmDialogConfig = {
    rejectButtonStyleClass: 'p-button-text p-button-plain',
    defaultFocus: 'none',
};
