/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SelettivaCheckCentraleDto } from '../../models/selettiva-check-centrale-dto';

export interface ApiSelettivaSelettivaCountCentraleGet$Params {
    /**
     * id della selettiva per cui verificare i dati
     */
    selettivaId: number;
}

export function apiSelettivaSelettivaCountCentraleGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiSelettivaSelettivaCountCentraleGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<SelettivaCheckCentraleDto>> {
    const rb = new RequestBuilder(rootUrl, apiSelettivaSelettivaCountCentraleGet.PATH, 'get');
    if (params) {
        rb.query('selettivaId', params.selettivaId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<SelettivaCheckCentraleDto>;
        })
    );
}

apiSelettivaSelettivaCountCentraleGet.PATH = '/api/selettiva/selettiva-count-centrale';
