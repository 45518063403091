/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LoginDto } from '../../models/login-dto';
import { TokenResponseDto } from '../../models/token-response-dto';

export interface ApiAuthLoginPost$Params {
    /**
     * application/json
     */
    body: LoginDto;
}

export function apiAuthLoginPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthLoginPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<TokenResponseDto>> {
    const rb = new RequestBuilder(rootUrl, apiAuthLoginPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<TokenResponseDto>;
        })
    );
}

apiAuthLoginPost.PATH = '/api/auth/login';
