import { TipoUtente } from '@modules/gestione-utenti/nuovo-utente-esterno/nuovo-utente-esterno.models';

export const getUserType = (type: number): string => {
    switch (type) {
        case TipoUtente.Administrator:
            return 'Admin';
        case TipoUtente.MonitorSelettive:
            return 'Monitor';
        case TipoUtente.CellulareCorpo:
            return 'Cellulare Corpo';
        case TipoUtente.TabletCorpo:
            return 'Tablet Corpo';
        case TipoUtente.CorpoPermanente:
            return 'Permanente';
        case TipoUtente.PortaleFederazione:
            return 'Federazione';
        case TipoUtente.UtenteEsterno:
            return 'Esterno';
        default:
            return 'N/A';
    }
};

export const getSessionType = (type: number): string => {
    switch (type) {
        case 1:
            return 'Web';
        case 2:
            return 'Mobile';
        case 3:
            return 'Api';
        default:
            return 'Unknown';
    }
};

export const getPushNotificationType = (type: number): string => {
    switch (type) {
        case 1:
            return 'Test';
        case 2:
            return 'Notifica';
        case 98:
            return 'Selettiva Secondaria';
        case 99:
            return 'Selettiva';
        default:
            return 'Unknown';
    }
};
