import { Component } from '@angular/core';

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent {
    constructor() {
        this.getChangesLogs();
    }

    public changes: Array<{
        version: string;
        date: string;
        items: string[];
    }> = [];

    // TODO: Add your changes here
    private getChangesLogs(): void {
        this.changes.push({
            version: '0.1.0',
            date: '2024-01-01',
            items: ['Start beta version', 'Add new features', 'Fix some bugs'],
        });
    }
}
