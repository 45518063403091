/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiNotificaNotificaRimuoviArchiviaPost$Params {
    /**
     * Id della notifica per cui rimuovere la lettura
     */
    notificaId: number;
}

export function apiNotificaNotificaRimuoviArchiviaPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiNotificaNotificaRimuoviArchiviaPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiNotificaNotificaRimuoviArchiviaPost.PATH, 'post');
    if (params) {
        rb.query('notificaId', params.notificaId, {});
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiNotificaNotificaRimuoviArchiviaPost.PATH = '/api/notifica/notifica-rimuovi-archivia';
