import { AccessTokenModel, AuthenticationProvider } from 'ngx-auth-utils';
import { AuthService } from '@core/http-gen/services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmRegisterUserDto, LoginDto, TokenResponseDto } from '@core/http-gen/models';
import { UtenteProfiloDto } from '@core/http-gen/models/utente-profilo-dto';

export class BackendAuthenticationProvider extends AuthenticationProvider {
    constructor(private authService: AuthService) {
        super();
    }

    private static mapAccessToken(tokenPair: TokenResponseDto): AccessTokenModel {
        {
            return {
                accessToken: tokenPair.token || '',
                refreshToken: tokenPair.refreshToken || '',
            };
        }
    }

    fetchUser(): Observable<UtenteProfiloDto> {
        return this.authService.apiAuthMeGet();
    }

    doLogin(credentials: LoginDto | ConfirmRegisterUserDto): Observable<AccessTokenModel> {
        if (this.isLogginIn(credentials)) {
            return this.authService.apiAuthLoginPost({ body: credentials }).pipe(map(BackendAuthenticationProvider.mapAccessToken));
        }

        return this.authService.apiAuthRegisterConfirmPost({ body: credentials }).pipe(map(BackendAuthenticationProvider.mapAccessToken));
    }

    private isLogginIn(credentials: LoginDto | ConfirmRegisterUserDto): boolean {
        return 'password' in credentials;
    }

    refreshToken(accessToken: string, refreshToken: string): Observable<AccessTokenModel> {
        return this.authService.apiAuthRefreshTokenPost({ body: { refreshToken } }).pipe(map(BackendAuthenticationProvider.mapAccessToken));
    }
}
