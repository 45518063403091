/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VvfVolontarioMansioneDto } from '../../models/vvf-volontario-mansione-dto';

export interface ApiOrganicoVolontarioListByMansioneGet$Params {
    /**
     * Id del grado per cui caricare i vigili
     */
    mansioneId: number;

    /**
     * Id dell'unione per cui caricare i volontari
     */
    unioneId?: number;

    /**
     * Id del corpo per cui caricare i volontari
     */
    corpoId?: number;
}

export function apiOrganicoVolontarioListByMansioneGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiOrganicoVolontarioListByMansioneGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<VvfVolontarioMansioneDto>>> {
    const rb = new RequestBuilder(rootUrl, apiOrganicoVolontarioListByMansioneGet.PATH, 'get');
    if (params) {
        rb.query('mansioneId', params.mansioneId, {});
        rb.query('unioneId', params.unioneId, {});
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<VvfVolontarioMansioneDto>>;
        })
    );
}

apiOrganicoVolontarioListByMansioneGet.PATH = '/api/organico/volontario-list-by-mansione';
