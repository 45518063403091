<div class="container-xxl">
    <h2 class="mt-3 mb-4">Changelog</h2>

    <div class="mb-4" *ngFor="let change of changes">
        <div class="d-flex flex-row">
            <h4>
                {{ change.version }} <span class="fw-normal">({{ change.date }})</span>
            </h4>
        </div>

        <p class="m-0 p-0" *ngFor="let item of change.items">{{ item }}</p>
    </div>
</div>
