import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-table-pagination',
    templateUrl: './table-pagination.component.html',
    styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent implements OnInit {
    @Input() currentPage = 1;
    @Input() itemsPerPage = 25;
    @Input() totalItems = 0;
    @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

    public paginationPageNumbersToDisplay: number[] = [];
    public paginationActionToDisplay = {
        prev: false,
        next: false,
    };

    constructor(private router: Router) {}

    public ngOnInit(): void {
        this.updatePagination();
    }

    public changePageNumber(page: number): void {
        if (page === this.currentPage) {
            return;
        }

        this.currentPage = page;
        this.router.navigate([], {
            queryParams: { page },
            queryParamsHandling: 'merge',
        });

        this.updatePagination();

        // Emit the new page number
        this.changePage.emit(page);
    }

    public updatePagination(): void {
        // Example:
        // < 1 ... 4 5 6 7 8 ... 10 >

        this.paginationActionToDisplay.next = false;
        this.paginationActionToDisplay.prev = false;
        this.paginationPageNumbersToDisplay = [];

        const lastPage = Math.ceil(this.totalItems / this.itemsPerPage);

        if (lastPage <= 1) {
            return;
        }

        if (this.currentPage > lastPage) {
            this.currentPage = lastPage;
        }

        if (this.currentPage < 1) {
            this.currentPage = 1;
        }

        // Actions
        if (this.currentPage !== 1) {
            this.paginationActionToDisplay.prev = true;
        }
        if (this.currentPage < lastPage) {
            this.paginationActionToDisplay.next = true;
        }

        // Pages
        // Generate the 7 central pages
        for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
            if (i >= 1 && i <= lastPage) {
                this.paginationPageNumbersToDisplay.push(i);
            }
        }

        // Add the last page
        if (this.paginationPageNumbersToDisplay[this.paginationPageNumbersToDisplay.length - 1] <= lastPage - 1) {
            if (this.paginationPageNumbersToDisplay[this.paginationPageNumbersToDisplay.length - 1] < lastPage - 1) {
                this.paginationPageNumbersToDisplay.push(NaN);
            }
            this.paginationPageNumbersToDisplay.push(lastPage);
        }

        // Add the first page
        if (this.paginationPageNumbersToDisplay[0] >= 2) {
            if (this.paginationPageNumbersToDisplay[0] > 2) {
                this.paginationPageNumbersToDisplay.unshift(NaN);
            }
            this.paginationPageNumbersToDisplay.unshift(1);
        }
    }

    public prevPage(): void {
        this.changePageNumber(this.currentPage - 1);
    }

    public nextPage(): void {
        this.changePageNumber(this.currentPage + 1);
    }

    public isNaN(value: number): boolean {
        return isNaN(value);
    }
}
