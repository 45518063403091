import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxAuthService } from 'ngx-auth-utils';
import { map } from 'rxjs/operators';
import { UtenteProfiloDto } from '@core/http-gen/models';
import { ToastService } from '@core/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    readonly DEFAULT_REDIRECT_PATH = '/';

    constructor(private authenticationService: NgxAuthService, private router: Router, private toastService: ToastService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.checkConditionsAndRedirect(route);
    }

    private checkConditionsAndRedirect(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.checkConditions(route).pipe(
            map((result) => {
                if (!result) {
                    this.toastService.error('Non hai i permessi per eseguire questa operazione');
                    const localRedirectRoute = route?.data?.permissions?.redirectRoute;
                    if (localRedirectRoute !== false) {
                        if (localRedirectRoute != null) {
                            return this.router.parseUrl(localRedirectRoute);
                        }

                        return this.router.parseUrl(this.DEFAULT_REDIRECT_PATH);
                    }
                }

                return result;
            })
        );
    }

    private checkConditions(route: ActivatedRouteSnapshot | Route): Observable<boolean | UrlTree> {
        return this.authenticationService.state.pipe(
            map((user: UtenteProfiloDto | null) => {
                if (!user) {
                    if (isDevMode()) {
                        // eslint-disable-next-line no-console
                        console.warn(
                            'PermissionGuard should be used only combined with AuthUserGuard, ' +
                                'as it expects the user to be authenticated.'
                        );
                        throw new Error('user-not-authenticated');
                    }

                    return false;
                }

                window.alert('RoleGuard checkConditions user ' + user.tokenData?.tipoUtente);

                if (user.tokenData?.tipoUtente === route.data?.tipoUtente) {
                    return true;
                }

                return false;
            })
        );
    }
}
