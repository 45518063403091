import { Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import * as olStyle from 'ol/style';
import { map } from 'rxjs/operators';
import { MarcatoreService } from '@core/http-gen/services/marcatore.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IdrantiSourceService {
    constructor(private marcatoreService: MarcatoreService) {}

    getSource(): Observable<VectorSource> {
        return this.marcatoreService
            .apiMarcatoreIdranteListByPosizionePost({
                body: {
                    topLeftPoint: {
                        x: 10.519868,
                        y: 46.452181,
                    },
                    topRightPoint: {
                        x: 12.119087,
                        y: 46.576185,
                    },
                    bottomLeftPoint: {
                        x: 10.281776,
                        y: 45.733835,
                    },
                    bottomRightPoint: {
                        x: 11.523124,
                        y: 45.618032,
                    },
                },
            })
            .pipe(
                map((idranti) => {
                    const idrantiSource = new VectorSource({});
                    idranti.forEach((idrante) => {
                        const idranteFeature = new Feature(new Point(olProj.fromLonLat([idrante.posizione.x, idrante.posizione.y])));
                        const iconStyle = new olStyle.Style({
                            image: new olStyle.Icon({
                                anchor: [0.5, 0],
                                anchorOrigin: 'bottom-left',
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'pixels',
                                size: [32, 32],
                                opacity: 0.75,
                                src: 'assets/images/icons/hydrant.svg',
                            }),
                        });
                        idranteFeature.setStyle(iconStyle);
                        idrantiSource.addFeature(idranteFeature);
                    });
                    return idrantiSource;
                })
            );
    }
}
