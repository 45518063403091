import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './ui/header/header.component';
import { NgxAuthUtilsModule } from 'ngx-auth-utils';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserInitialsPipe } from './auth/user-initials.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UserDisplayPipe } from './auth/user-display.pipe';
import { SharedModule } from '@shared/shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { FooterComponent } from './ui/footer/footer.component';
import { ToastComponent } from './ui/toast/toast.component';

@NgModule({
    declarations: [HeaderComponent, UserInitialsPipe, UserDisplayPipe, FooterComponent, ToastComponent],
    imports: [
        CommonModule,
        NgxAuthUtilsModule,
        ButtonModule,
        RouterModule,
        FontAwesomeModule,
        ConfirmDialogModule,
        SharedModule,
        TooltipModule,
    ],
    exports: [UserDisplayPipe, UserInitialsPipe, ToastComponent],
})
export class CoreModule {}
