/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MarcatoreDto } from '../../models/marcatore-dto';
import { MarcatoreListByPosizioneRequestDto } from '../../models/marcatore-list-by-posizione-request-dto';

export interface ApiMarcatoreMarcatoreListByPosizionePost$Params {
    /**
     * Oggetto contenente la posizione dei quattro angoli che delimitano il rettangolo e i tipi di marcatore da caricare
     */
    body: MarcatoreListByPosizioneRequestDto;
}

export function apiMarcatoreMarcatoreListByPosizionePost(
    http: HttpClient,
    rootUrl: string,
    params: ApiMarcatoreMarcatoreListByPosizionePost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<MarcatoreDto>>> {
    const rb = new RequestBuilder(rootUrl, apiMarcatoreMarcatoreListByPosizionePost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<MarcatoreDto>>;
        })
    );
}

apiMarcatoreMarcatoreListByPosizionePost.PATH = '/api/marcatore/marcatore-list-by-posizione';
