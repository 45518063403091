import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { THEME_ICONS } from '@shared/faIcons';
import { catchError, finalize } from 'rxjs/operators';
import { ToastService } from '@core/services/toast.service';
import { EMPTY } from 'rxjs';
import { getValueOrError } from '@core/errors/exception';
import { ConfirmationService } from 'primeng/api';
import { NumeroUtileDto, RecapitoDto, RecapitoVigileDto } from '@core/http-gen/models';
import { NumeroUtileService, RecapitoService } from '@core/http-gen/services';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { NgxAuthService } from 'ngx-auth-utils';
import { Permesso, PermessoValore } from '@shared/types/auth.models';

@Component({
    selector: 'app-numeri-utili',
    templateUrl: './numeri-utili.component.html',
    styleUrls: ['./numeri-utili.component.scss'],
})
export class NumeriUtiliComponent implements OnInit {
    icons = THEME_ICONS;
    numeriUtili: Array<NumeroUtileDto> = [];
    @ViewChild('dt') table!: Table;
    columns = [
        { field: 'nome', header: 'Nome' },
        { field: 'cognome', header: 'Cognome' },
        { field: 'azienda', header: 'Azienda' },
        { field: 'email', header: 'Email' },
        { field: 'telefono1', header: 'Telefono 1' },
        { field: 'telefono2', header: 'Telefono 2' },
        { field: 'issiRadio', header: 'IssiRadio' },
        { field: 'dropdown', header: '' },
    ];
    fields: string[] = [];
    addingNew = false;
    saving = false;
    loadingList = true;
    canDelete = false;
    canUpdate = false;
    canCreate = false;

    rubrica: Array<RecapitoDto> = [];
    singleVigile: Array<RecapitoVigileDto> = [];
    singleVigileNome = '';
    loadingRubrica = true;
    enableViewCorpoList = true;

    constructor(
        private numeroUtileService: NumeroUtileService,
        private toastService: ToastService,
        private confirmationService: ConfirmationService,
        private permissionChecker: PermissionCheckerService,
        private authService: NgxAuthService,
        private recapitoService: RecapitoService
    ) {}

    loadContacts(): void {
        this.loadingList = true;
        this.numeroUtileService.apiNumeroUtileNumeroUtileListGet().subscribe((numeriUtili) => {
            this.numeriUtili = numeriUtili;
            this.loadingList = false;
        });
    }

    loadRubrica(): void {
        this.loadingRubrica = true;
        this.recapitoService.apiRecapitoRecapitoCorpoListGet().subscribe((rubrica) => {
            this.rubrica = rubrica;
            this.loadingRubrica = false;
        });
    }

    ngOnInit(): void {
        this.fields = this.columns.map((col) => col.field);
        this.loadContacts();

        this.canDelete = this.permissionChecker.hasThePermission(
            Permesso.RubricaTelefonica,
            PermessoValore.Cancellazione,
            this.authService.snapshot.user.tokenData.permessoList
        );

        this.canUpdate = this.permissionChecker.hasThePermission(
            Permesso.RubricaTelefonica,
            PermessoValore.Modifica,
            this.authService.snapshot.user.tokenData.permessoList
        );

        this.canCreate = this.permissionChecker.hasThePermission(
            Permesso.RubricaTelefonica,
            PermessoValore.Scrittura,
            this.authService.snapshot.user.tokenData.permessoList
        );

        this.loadRubrica();
    }

    onSelectionChange(event: Array<RecapitoDto>): void {
        const tmp = event.pop();
        const matr = tmp?.matricola as string;
        const nome = tmp?.cognome + ' ' + tmp?.nome;
        if (nome !== undefined) {
            this.singleVigileNome = nome;
        }
        if (matr !== undefined) {
            this.recapitoService.apiRecapitoRecapitoVigileListGet({ matricola: matr }).subscribe((selectedVigile) => {
                this.singleVigile = selectedVigile;
            });
        }
    }

    onRowEditInit(): void {
        this.addingNew = true;
    }

    onRowEditSave(numeroUtile: NumeroUtileDto): void {
        this.saving = true;
        this.numeroUtileService
            .apiNumeroUtileNumeroUtileInsertPost({ body: numeroUtile })
            .pipe(
                catchError(() => {
                    this.toastService.error('Errore nel salvataggio del contatto');
                    return EMPTY;
                }),
                finalize(() => {
                    this.saving = false;
                    this.addingNew = false;
                })
            )
            .subscribe(() => {
                this.loadContacts();
                this.addingNew = false;
                this.saving = false;
            });
    }

    onRowEditCancel(numeroUtile: NumeroUtileDto): void {
        this.addingNew = false;
        if (!numeroUtile.id) {
            this.numeriUtili.shift();
        }
    }

    deleteNumeroUtile(contact: NumeroUtileDto): void {
        this.confirmationService.confirm({
            message: 'Sei sicuro di voler procedere?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sì',
            rejectLabel: 'No',
            accept: () => {
                this.onRowDelete(contact);
            },
        });
    }

    onRowDelete(contact: NumeroUtileDto): void {
        this.saving = true;
        this.numeroUtileService
            .apiNumeroUtileDeleteNumeroUtileByIdDelete({ numeroUtileId: getValueOrError(contact.id) })
            .pipe(
                catchError(() => {
                    this.toastService.error("Errore nell'eliminazione del contatto");
                    return EMPTY;
                }),
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.loadContacts();
                this.saving = false;
            });
    }

    aggiungiInline(): void {
        this.addingNew = true;
        const empty = {
            azienda: '',
            cognome: '',
            email: '',
            id: undefined,
            idCorpo: undefined,
            issiRadio: undefined,
            nome: '',
            telefono1: '',
            telefono2: '',
        } as NumeroUtileDto;
        this.numeriUtili.unshift(empty);
        this.table.initRowEdit(empty);
    }
}
