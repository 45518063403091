/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RuoloUtenteDto } from '../../models/ruolo-utente-dto';

export interface ApiAuthRuoliUtenteUpdatePost$Params {
    /**
     * Id dell'utente per cui caricare i ruoli
     */
    utenteId: number;

    /**
     * Lista dei ruoli associati ad un utente
     */
    body: Array<RuoloUtenteDto>;
}

export function apiAuthRuoliUtenteUpdatePost(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthRuoliUtenteUpdatePost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiAuthRuoliUtenteUpdatePost.PATH, 'post');
    if (params) {
        rb.query('utenteId', params.utenteId, {});
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiAuthRuoliUtenteUpdatePost.PATH = '/api/auth/ruoli-utente-update';
