<!-- <nav aria-label="Page navigation" class="mt-3 select-none">
    <ul class="pagination justify-content-center">
        <li *ngIf="paginationActionToDisplay.prev" class="page-item">
            <a class="page-link cursor-pointer" (click)="prevPage()" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <li class="page-item cursor-pointer" *ngFor="let page of paginationPageNumbersToDisplay" [class.active]="page === currentPage">
            <a class="page-link" (click)="changePageNumber(page)">{{ page }}</a>
        </li>
        <li *ngIf="paginationActionToDisplay.next" class="page-item">
            <a class="page-link cursor-pointer" (click)="nextPage()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</nav> -->
<nav aria-label="Page navigation" class="mt-3 select-none">
    <ul class="pagination justify-content-center">
        <li *ngIf="paginationActionToDisplay.prev" class="page-item">
            <a class="page-link cursor-pointer" (click)="prevPage()" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <li class="page-item" *ngFor="let page of paginationPageNumbersToDisplay" [class.active]="page === currentPage">
            <a class="page-link {{ isNaN(page) ? 'disabled' : 'cursor-pointer' }}" (click)="!isNaN(page) && changePageNumber(page)">{{
                isNaN(page) ? "..." : page
            }}</a>
        </li>
        <li *ngIf="paginationActionToDisplay.next" class="page-item">
            <a class="page-link cursor-pointer" (click)="nextPage()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</nav>
