<div style="position: fixed; top: 0; right: 0; z-index: 4000">
    <ng-container *ngFor="let toast of toasts">
        <div class="toast show mt-2 me-2 toast-{{ toast.status }}">
            <div class="toast-header toast-{{ toast.status }}">
                <strong class="me-auto">{{ toast.header }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                {{ toast.body }}
            </div>
        </div>
    </ng-container>
</div>
