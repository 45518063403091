<p-blockUI [blocked]="(loading$ | async) || false"></p-blockUI>
<router-outlet name="toast"></router-outlet>
<p-confirmDialog [style]="{ width: '30vw', minWidth: '400px', maxWidth: '600px' }"></p-confirmDialog>

<app-toast></app-toast>
<router-outlet name="header" *ngIf="showHeader"></router-outlet>

<div class="wrapper d-flex flex-column {{ showHeader ? 'min-height-100-with-header' : 'min-height-100-without-header' }}">
    <div class="flex-grow-1">
        <router-outlet></router-outlet>
    </div>
    <router-outlet name="footer"></router-outlet>
</div>
