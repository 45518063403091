/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAlertcallsCountGet } from '../fn/alertcalls/api-alertcalls-count-get';
import { ApiAlertcallsCountGet$Params } from '../fn/alertcalls/api-alertcalls-count-get';
import { apiAlertcallsGet } from '../fn/alertcalls/api-alertcalls-get';
import { ApiAlertcallsGet$Params } from '../fn/alertcalls/api-alertcalls-get';
import { apiAlertcallsIdAnswersGet } from '../fn/alertcalls/api-alertcalls-id-answers-get';
import { ApiAlertcallsIdAnswersGet$Params } from '../fn/alertcalls/api-alertcalls-id-answers-get';
import { apiAlertcallsIdGet } from '../fn/alertcalls/api-alertcalls-id-get';
import { ApiAlertcallsIdGet$Params } from '../fn/alertcalls/api-alertcalls-id-get';
import { DefaultMultiRecordReturnDto } from '../models/default-multi-record-return-dto';
import { DefaultSingleRecordReturnDto } from '../models/default-single-record-return-dto';

@Injectable({ providedIn: 'root' })
export class AlertcallsService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiAlertcallsGet()` */
    static readonly ApiAlertcallsGetPath = '/api/alertcalls';

    /**
     * Get all alertcalls.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAlertcallsGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsGet$Response(
        params?: ApiAlertcallsGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiAlertcallsGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get all alertcalls.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAlertcallsGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsGet(params?: ApiAlertcallsGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiAlertcallsGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiAlertcallsIdGet()` */
    static readonly ApiAlertcallsIdGetPath = '/api/alertcalls/{id}';

    /**
     * Get a alertcall by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAlertcallsIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsIdGet$Response(
        params: ApiAlertcallsIdGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiAlertcallsIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get a alertcall by id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAlertcallsIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsIdGet(params: ApiAlertcallsIdGet$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiAlertcallsIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiAlertcallsCountGet()` */
    static readonly ApiAlertcallsCountGetPath = '/api/alertcalls/count';

    /**
     * Get the count of alertcalls.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAlertcallsCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsCountGet$Response(
        params?: ApiAlertcallsCountGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiAlertcallsCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get the count of alertcalls.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAlertcallsCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsCountGet(params?: ApiAlertcallsCountGet$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiAlertcallsCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiAlertcallsIdAnswersGet()` */
    static readonly ApiAlertcallsIdAnswersGetPath = '/api/alertcalls/{id}/answers';

    /**
     * Add an answer to an alertcall.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAlertcallsIdAnswersGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsIdAnswersGet$Response(
        params: ApiAlertcallsIdAnswersGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiAlertcallsIdAnswersGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Add an answer to an alertcall.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAlertcallsIdAnswersGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAlertcallsIdAnswersGet(params: ApiAlertcallsIdAnswersGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiAlertcallsIdAnswersGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body)
        );
    }
}
