export const environment = {
    production: true,
    enableDevModule: false,
    firebase: {
        apiKey: 'AIzaSyBqH1f9qcmWoXYB4sl263NI-jMyCq7POxs',
        authDomain: 'firepi-c19dc.firebaseapp.com',
        projectId: 'firepi-c19dc',
        storageBucket: 'firepi-c19dc.appspot.com',
        messagingSenderId: '573883249847',
        appId: '1:573883249847:web:aafaa6fff0864387700932',
        vapidKey: 'BDOAEzjS6G1AtgX7V6LtrFLLrc2h2mQaM2fTNMAHavgmgPQkM384fU2jjMLMDXiNZW5J7WMB_QqYnaIf9nY8QGc',
    },
};
