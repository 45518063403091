/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAuthLoginPost } from '../fn/auth/api-auth-login-post';
import { ApiAuthLoginPost$Params } from '../fn/auth/api-auth-login-post';
import { apiAuthLogoutDelete } from '../fn/auth/api-auth-logout-delete';
import { ApiAuthLogoutDelete$Params } from '../fn/auth/api-auth-logout-delete';
import { apiAuthMeGet } from '../fn/auth/api-auth-me-get';
import { ApiAuthMeGet$Params } from '../fn/auth/api-auth-me-get';
import { apiAuthPermesoUtenteListGet } from '../fn/auth/api-auth-permeso-utente-list-get';
import { ApiAuthPermesoUtenteListGet$Params } from '../fn/auth/api-auth-permeso-utente-list-get';
import { apiAuthPermessoUtenteModificaListGet } from '../fn/auth/api-auth-permesso-utente-modifica-list-get';
import { ApiAuthPermessoUtenteModificaListGet$Params } from '../fn/auth/api-auth-permesso-utente-modifica-list-get';
import { apiAuthPermessoUtenteModificaPost } from '../fn/auth/api-auth-permesso-utente-modifica-post';
import { ApiAuthPermessoUtenteModificaPost$Params } from '../fn/auth/api-auth-permesso-utente-modifica-post';
import { apiAuthRefreshTokenPost } from '../fn/auth/api-auth-refresh-token-post';
import { ApiAuthRefreshTokenPost$Params } from '../fn/auth/api-auth-refresh-token-post';
import { apiAuthRegisterConfirmPost } from '../fn/auth/api-auth-register-confirm-post';
import { ApiAuthRegisterConfirmPost$Params } from '../fn/auth/api-auth-register-confirm-post';
import { apiAuthRegisterPost } from '../fn/auth/api-auth-register-post';
import { ApiAuthRegisterPost$Params } from '../fn/auth/api-auth-register-post';
import { apiAuthRegisterUtenteEsternoPost } from '../fn/auth/api-auth-register-utente-esterno-post';
import { ApiAuthRegisterUtenteEsternoPost$Params } from '../fn/auth/api-auth-register-utente-esterno-post';
import { apiAuthResetUserPasswordConfirmPost } from '../fn/auth/api-auth-reset-user-password-confirm-post';
import { ApiAuthResetUserPasswordConfirmPost$Params } from '../fn/auth/api-auth-reset-user-password-confirm-post';
import { apiAuthResetUserPasswordPost } from '../fn/auth/api-auth-reset-user-password-post';
import { ApiAuthResetUserPasswordPost$Params } from '../fn/auth/api-auth-reset-user-password-post';
import { apiAuthRuoliUtenteUpdatePost } from '../fn/auth/api-auth-ruoli-utente-update-post';
import { ApiAuthRuoliUtenteUpdatePost$Params } from '../fn/auth/api-auth-ruoli-utente-update-post';
import { apiAuthRuoloByIdGet } from '../fn/auth/api-auth-ruolo-by-id-get';
import { ApiAuthRuoloByIdGet$Params } from '../fn/auth/api-auth-ruolo-by-id-get';
import { apiAuthRuoloEditPost } from '../fn/auth/api-auth-ruolo-edit-post';
import { ApiAuthRuoloEditPost$Params } from '../fn/auth/api-auth-ruolo-edit-post';
import { apiAuthRuoloInsertPost } from '../fn/auth/api-auth-ruolo-insert-post';
import { ApiAuthRuoloInsertPost$Params } from '../fn/auth/api-auth-ruolo-insert-post';
import { apiAuthRuoloListGet } from '../fn/auth/api-auth-ruolo-list-get';
import { ApiAuthRuoloListGet$Params } from '../fn/auth/api-auth-ruolo-list-get';
import { apiAuthRuoloPermessoListGet } from '../fn/auth/api-auth-ruolo-permesso-list-get';
import { ApiAuthRuoloPermessoListGet$Params } from '../fn/auth/api-auth-ruolo-permesso-list-get';
import { apiAuthRuoloUtenteListGet } from '../fn/auth/api-auth-ruolo-utente-list-get';
import { ApiAuthRuoloUtenteListGet$Params } from '../fn/auth/api-auth-ruolo-utente-list-get';
import { apiAuthUpdateRuoloPermessoListPost } from '../fn/auth/api-auth-update-ruolo-permesso-list-post';
import { ApiAuthUpdateRuoloPermessoListPost$Params } from '../fn/auth/api-auth-update-ruolo-permesso-list-post';
import { apiAuthUtenteAdministratorListGet } from '../fn/auth/api-auth-utente-administrator-list-get';
import { ApiAuthUtenteAdministratorListGet$Params } from '../fn/auth/api-auth-utente-administrator-list-get';
import { apiAuthUtenteAppartenenzaListGet } from '../fn/auth/api-auth-utente-appartenenza-list-get';
import { ApiAuthUtenteAppartenenzaListGet$Params } from '../fn/auth/api-auth-utente-appartenenza-list-get';
import { apiAuthUtenteAppartenenzaUpdatePost } from '../fn/auth/api-auth-utente-appartenenza-update-post';
import { ApiAuthUtenteAppartenenzaUpdatePost$Params } from '../fn/auth/api-auth-utente-appartenenza-update-post';
import { apiAuthUtenteCorpoListGet } from '../fn/auth/api-auth-utente-corpo-list-get';
import { ApiAuthUtenteCorpoListGet$Params } from '../fn/auth/api-auth-utente-corpo-list-get';
import { apiAuthUtenteDeleteDelete } from '../fn/auth/api-auth-utente-delete-delete';
import { ApiAuthUtenteDeleteDelete$Params } from '../fn/auth/api-auth-utente-delete-delete';
import { apiAuthUtenteEsternoEditGet } from '../fn/auth/api-auth-utente-esterno-edit-get';
import { ApiAuthUtenteEsternoEditGet$Params } from '../fn/auth/api-auth-utente-esterno-edit-get';
import { apiAuthUtenteEsternoUpdatePatch } from '../fn/auth/api-auth-utente-esterno-update-patch';
import { ApiAuthUtenteEsternoUpdatePatch$Params } from '../fn/auth/api-auth-utente-esterno-update-patch';
import { apiAuthUtenteFederazioneListGet } from '../fn/auth/api-auth-utente-federazione-list-get';
import { ApiAuthUtenteFederazioneListGet$Params } from '../fn/auth/api-auth-utente-federazione-list-get';
import { apiAuthUtentePermanenteListGet } from '../fn/auth/api-auth-utente-permanente-list-get';
import { ApiAuthUtentePermanenteListGet$Params } from '../fn/auth/api-auth-utente-permanente-list-get';
import { apiAuthUtenteProfiloGet } from '../fn/auth/api-auth-utente-profilo-get';
import { ApiAuthUtenteProfiloGet$Params } from '../fn/auth/api-auth-utente-profilo-get';
import { apiAuthUtenteUnioneListGet } from '../fn/auth/api-auth-utente-unione-list-get';
import { ApiAuthUtenteUnioneListGet$Params } from '../fn/auth/api-auth-utente-unione-list-get';
import { PermessoUtenteDto } from '../models/permesso-utente-dto';
import { PermessoUtenteModificaDto } from '../models/permesso-utente-modifica-dto';
import { RegisterUserResponseDto } from '../models/register-user-response-dto';
import { ResetUserPasswordResponseDto } from '../models/reset-user-password-response-dto';
import { RuoloDto } from '../models/ruolo-dto';
import { RuoloPermessoDto } from '../models/ruolo-permesso-dto';
import { RuoloUtenteDto } from '../models/ruolo-utente-dto';
import { TokenResponseDto } from '../models/token-response-dto';
import { UtenteAppartenenzaConFederazioneDto } from '../models/utente-appartenenza-con-federazione-dto';
import { UtenteEsternoEditDto } from '../models/utente-esterno-edit-dto';
import { UtenteListItemDto } from '../models/utente-list-item-dto';
import { UtenteProfiloDto } from '../models/utente-profilo-dto';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiAuthRegisterPost()` */
    static readonly ApiAuthRegisterPostPath = '/api/auth/register';

    /**
     * Registra un nuovo utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRegisterPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterPost$Response(
        params: ApiAuthRegisterPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<RegisterUserResponseDto>> {
        return apiAuthRegisterPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Registra un nuovo utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRegisterPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterPost(params: ApiAuthRegisterPost$Params, context?: HttpContext): Observable<RegisterUserResponseDto> {
        return this.apiAuthRegisterPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<RegisterUserResponseDto>): RegisterUserResponseDto => r.body)
        );
    }

    /** Path part for operation `apiAuthResetUserPasswordPost()` */
    static readonly ApiAuthResetUserPasswordPostPath = '/api/auth/reset-user-password';

    /**
     * Richiede il reset della password per un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthResetUserPasswordPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthResetUserPasswordPost$Response(
        params: ApiAuthResetUserPasswordPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<ResetUserPasswordResponseDto>> {
        return apiAuthResetUserPasswordPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Richiede il reset della password per un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthResetUserPasswordPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthResetUserPasswordPost(
        params: ApiAuthResetUserPasswordPost$Params,
        context?: HttpContext
    ): Observable<ResetUserPasswordResponseDto> {
        return this.apiAuthResetUserPasswordPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<ResetUserPasswordResponseDto>): ResetUserPasswordResponseDto => r.body)
        );
    }

    /** Path part for operation `apiAuthResetUserPasswordConfirmPost()` */
    static readonly ApiAuthResetUserPasswordConfirmPostPath = '/api/auth/reset-user-password-confirm';

    /**
     * Richiesta di conferma di cambio password per un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthResetUserPasswordConfirmPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthResetUserPasswordConfirmPost$Response(
        params: ApiAuthResetUserPasswordConfirmPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiAuthResetUserPasswordConfirmPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Richiesta di conferma di cambio password per un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthResetUserPasswordConfirmPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthResetUserPasswordConfirmPost(params: ApiAuthResetUserPasswordConfirmPost$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthResetUserPasswordConfirmPost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthRegisterConfirmPost()` */
    static readonly ApiAuthRegisterConfirmPostPath = '/api/auth/register-confirm';

    /**
     * Completa la registrazione di un nuovo utente e restituisce i dati di autenticazione (Token e RefreshToken).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRegisterConfirmPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterConfirmPost$Response(
        params: ApiAuthRegisterConfirmPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<TokenResponseDto>> {
        return apiAuthRegisterConfirmPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Completa la registrazione di un nuovo utente e restituisce i dati di autenticazione (Token e RefreshToken).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRegisterConfirmPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterConfirmPost(params: ApiAuthRegisterConfirmPost$Params, context?: HttpContext): Observable<TokenResponseDto> {
        return this.apiAuthRegisterConfirmPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<TokenResponseDto>): TokenResponseDto => r.body)
        );
    }

    /** Path part for operation `apiAuthRegisterUtenteEsternoPost()` */
    static readonly ApiAuthRegisterUtenteEsternoPostPath = '/api/auth/register-utente-esterno';

    /**
     * Registra un nuovo utente non legato al database della federazione. Solitamente sono utenti relativi a monitor, tablet, permanenti, e soggetti esterni che non hanno una matricola nel databse della federazione.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRegisterUtenteEsternoPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterUtenteEsternoPost$Response(
        params: ApiAuthRegisterUtenteEsternoPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<number>> {
        return apiAuthRegisterUtenteEsternoPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Registra un nuovo utente non legato al database della federazione. Solitamente sono utenti relativi a monitor, tablet, permanenti, e soggetti esterni che non hanno una matricola nel databse della federazione.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRegisterUtenteEsternoPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRegisterUtenteEsternoPost(params: ApiAuthRegisterUtenteEsternoPost$Params, context?: HttpContext): Observable<number> {
        return this.apiAuthRegisterUtenteEsternoPost$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
    }

    /** Path part for operation `apiAuthLoginPost()` */
    static readonly ApiAuthLoginPostPath = '/api/auth/login';

    /**
     * Restituisce i dati di autenticazione (Token e RefreshToken) di un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthLoginPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthLoginPost$Response(params: ApiAuthLoginPost$Params, context?: HttpContext): Observable<StrictHttpResponse<TokenResponseDto>> {
        return apiAuthLoginPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Restituisce i dati di autenticazione (Token e RefreshToken) di un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthLoginPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthLoginPost(params: ApiAuthLoginPost$Params, context?: HttpContext): Observable<TokenResponseDto> {
        return this.apiAuthLoginPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<TokenResponseDto>): TokenResponseDto => r.body)
        );
    }

    /** Path part for operation `apiAuthLogoutDelete()` */
    static readonly ApiAuthLogoutDeletePath = '/api/auth/logout';

    /**
     * Effettua il logout dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthLogoutDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthLogoutDelete$Response(params?: ApiAuthLogoutDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return apiAuthLogoutDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * Effettua il logout dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthLogoutDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthLogoutDelete(params?: ApiAuthLogoutDelete$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthLogoutDelete$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthRefreshTokenPost()` */
    static readonly ApiAuthRefreshTokenPostPath = '/api/auth/refresh-token';

    /**
     * Restituisce i dati di autenticazione (Token e RefreshToken) di un utente dato un RefreshToken.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRefreshTokenPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRefreshTokenPost$Response(
        params: ApiAuthRefreshTokenPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<TokenResponseDto>> {
        return apiAuthRefreshTokenPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Restituisce i dati di autenticazione (Token e RefreshToken) di un utente dato un RefreshToken.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRefreshTokenPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRefreshTokenPost(params: ApiAuthRefreshTokenPost$Params, context?: HttpContext): Observable<TokenResponseDto> {
        return this.apiAuthRefreshTokenPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<TokenResponseDto>): TokenResponseDto => r.body)
        );
    }

    /** Path part for operation `apiAuthMeGet()` */
    static readonly ApiAuthMeGetPath = '/api/auth/me';

    /**
     * Restituisce i dati dell'utente loggato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthMeGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthMeGet$Response(params?: ApiAuthMeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<UtenteProfiloDto>> {
        return apiAuthMeGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Restituisce i dati dell'utente loggato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthMeGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthMeGet(params?: ApiAuthMeGet$Params, context?: HttpContext): Observable<UtenteProfiloDto> {
        return this.apiAuthMeGet$Response(params, context).pipe(map((r: StrictHttpResponse<UtenteProfiloDto>): UtenteProfiloDto => r.body));
    }

    /** Path part for operation `apiAuthUtenteProfiloGet()` */
    static readonly ApiAuthUtenteProfiloGetPath = '/api/auth/utente-profilo';

    /**
     * Carica il profilo di un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteProfiloGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteProfiloGet$Response(
        params: ApiAuthUtenteProfiloGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<UtenteProfiloDto>> {
        return apiAuthUtenteProfiloGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica il profilo di un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteProfiloGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteProfiloGet(params: ApiAuthUtenteProfiloGet$Params, context?: HttpContext): Observable<UtenteProfiloDto> {
        return this.apiAuthUtenteProfiloGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<UtenteProfiloDto>): UtenteProfiloDto => r.body)
        );
    }

    /** Path part for operation `apiAuthPermesoUtenteListGet()` */
    static readonly ApiAuthPermesoUtenteListGetPath = '/api/auth/permeso-utente-list';

    /**
     * Carica la lista dei permessi abilitati per l'utente del jwt token.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthPermesoUtenteListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthPermesoUtenteListGet$Response(
        params?: ApiAuthPermesoUtenteListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<PermessoUtenteDto>>> {
        return apiAuthPermesoUtenteListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei permessi abilitati per l'utente del jwt token.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthPermesoUtenteListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthPermesoUtenteListGet(params?: ApiAuthPermesoUtenteListGet$Params, context?: HttpContext): Observable<Array<PermessoUtenteDto>> {
        return this.apiAuthPermesoUtenteListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<PermessoUtenteDto>>): Array<PermessoUtenteDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthRuoloListGet()` */
    static readonly ApiAuthRuoloListGetPath = '/api/auth/ruolo-list';

    /**
     * Carica la lista di tutti i ruoli presenti nel sistema.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloListGet$Response(
        params?: ApiAuthRuoloListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<RuoloDto>>> {
        return apiAuthRuoloListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista di tutti i ruoli presenti nel sistema.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloListGet(params?: ApiAuthRuoloListGet$Params, context?: HttpContext): Observable<Array<RuoloDto>> {
        return this.apiAuthRuoloListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<RuoloDto>>): Array<RuoloDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthRuoloByIdGet()` */
    static readonly ApiAuthRuoloByIdGetPath = '/api/auth/ruolo-by-id';

    /**
     * Carica la lista di tutti i ruoli presenti nel sistema.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloByIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloByIdGet$Response(params: ApiAuthRuoloByIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<RuoloDto>> {
        return apiAuthRuoloByIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista di tutti i ruoli presenti nel sistema.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloByIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloByIdGet(params: ApiAuthRuoloByIdGet$Params, context?: HttpContext): Observable<RuoloDto> {
        return this.apiAuthRuoloByIdGet$Response(params, context).pipe(map((r: StrictHttpResponse<RuoloDto>): RuoloDto => r.body));
    }

    /** Path part for operation `apiAuthRuoloInsertPost()` */
    static readonly ApiAuthRuoloInsertPostPath = '/api/auth/ruolo-insert';

    /**
     * Inserisce una nuova entità all'interno della tabella dei ruoli.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloInsertPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoloInsertPost$Response(params: ApiAuthRuoloInsertPost$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
        return apiAuthRuoloInsertPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce una nuova entità all'interno della tabella dei ruoli.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloInsertPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoloInsertPost(params: ApiAuthRuoloInsertPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiAuthRuoloInsertPost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiAuthRuoloEditPost()` */
    static readonly ApiAuthRuoloEditPostPath = '/api/auth/ruolo-edit';

    /**
     * Modifica un ruolo già esistente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloEditPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoloEditPost$Response(params: ApiAuthRuoloEditPost$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
        return apiAuthRuoloEditPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Modifica un ruolo già esistente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloEditPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoloEditPost(params: ApiAuthRuoloEditPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiAuthRuoloEditPost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiAuthRuoloPermessoListGet()` */
    static readonly ApiAuthRuoloPermessoListGetPath = '/api/auth/ruolo-permesso-list';

    /**
     * Carica la lista dei permessi associati ad un ruolo con il relativo valore,
     * se viene specificato un corpo verrà data priorità ai permessi del corpo
     * Aggiunge agli eventuali permessi associati al ruolo, quelli mancanti con valore negativo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloPermessoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloPermessoListGet$Response(
        params: ApiAuthRuoloPermessoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<RuoloPermessoDto>>> {
        return apiAuthRuoloPermessoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei permessi associati ad un ruolo con il relativo valore,
     * se viene specificato un corpo verrà data priorità ai permessi del corpo
     * Aggiunge agli eventuali permessi associati al ruolo, quelli mancanti con valore negativo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloPermessoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloPermessoListGet(params: ApiAuthRuoloPermessoListGet$Params, context?: HttpContext): Observable<Array<RuoloPermessoDto>> {
        return this.apiAuthRuoloPermessoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<RuoloPermessoDto>>): Array<RuoloPermessoDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUpdateRuoloPermessoListPost()` */
    static readonly ApiAuthUpdateRuoloPermessoListPostPath = '/api/auth/update-ruolo-permesso-list';

    /**
     * Accetta una lista di permessi associati ad uno o più ruoli e li aggiorna sul database.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUpdateRuoloPermessoListPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUpdateRuoloPermessoListPost$Response(
        params: ApiAuthUpdateRuoloPermessoListPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiAuthUpdateRuoloPermessoListPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Accetta una lista di permessi associati ad uno o più ruoli e li aggiorna sul database.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUpdateRuoloPermessoListPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUpdateRuoloPermessoListPost(params: ApiAuthUpdateRuoloPermessoListPost$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthUpdateRuoloPermessoListPost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthRuoloUtenteListGet()` */
    static readonly ApiAuthRuoloUtenteListGetPath = '/api/auth/ruolo-utente-list';

    /**
     * Carica la lista dei ruoli associati ad un utente e dei ruoli mancanti disattivati.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoloUtenteListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloUtenteListGet$Response(
        params: ApiAuthRuoloUtenteListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<RuoloUtenteDto>>> {
        return apiAuthRuoloUtenteListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei ruoli associati ad un utente e dei ruoli mancanti disattivati.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoloUtenteListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthRuoloUtenteListGet(params: ApiAuthRuoloUtenteListGet$Params, context?: HttpContext): Observable<Array<RuoloUtenteDto>> {
        return this.apiAuthRuoloUtenteListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<RuoloUtenteDto>>): Array<RuoloUtenteDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthRuoliUtenteUpdatePost()` */
    static readonly ApiAuthRuoliUtenteUpdatePostPath = '/api/auth/ruoli-utente-update';

    /**
     * Inserisce o elimina i ruoli associati ad un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthRuoliUtenteUpdatePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoliUtenteUpdatePost$Response(
        params: ApiAuthRuoliUtenteUpdatePost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiAuthRuoliUtenteUpdatePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce o elimina i ruoli associati ad un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthRuoliUtenteUpdatePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthRuoliUtenteUpdatePost(params: ApiAuthRuoliUtenteUpdatePost$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthRuoliUtenteUpdatePost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthPermessoUtenteModificaListGet()` */
    static readonly ApiAuthPermessoUtenteModificaListGetPath = '/api/auth/permesso-utente-modifica-list';

    /**
     * Carica la lista dei permessi associati ad un utente, suddivisi per provenienza (da ruolo o assegnazione diretta), ad esclusione dei permessi non modificabili dall'utente richiedente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthPermessoUtenteModificaListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthPermessoUtenteModificaListGet$Response(
        params: ApiAuthPermessoUtenteModificaListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<PermessoUtenteModificaDto>>> {
        return apiAuthPermessoUtenteModificaListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei permessi associati ad un utente, suddivisi per provenienza (da ruolo o assegnazione diretta), ad esclusione dei permessi non modificabili dall'utente richiedente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthPermessoUtenteModificaListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthPermessoUtenteModificaListGet(
        params: ApiAuthPermessoUtenteModificaListGet$Params,
        context?: HttpContext
    ): Observable<Array<PermessoUtenteModificaDto>> {
        return this.apiAuthPermessoUtenteModificaListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<PermessoUtenteModificaDto>>): Array<PermessoUtenteModificaDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthPermessoUtenteModificaPost()` */
    static readonly ApiAuthPermessoUtenteModificaPostPath = '/api/auth/permesso-utente-modifica';

    /**
     * Aggiorna i permessi abilitati per un utente su un determinato corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthPermessoUtenteModificaPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthPermessoUtenteModificaPost$Response(
        params: ApiAuthPermessoUtenteModificaPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiAuthPermessoUtenteModificaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiorna i permessi abilitati per un utente su un determinato corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthPermessoUtenteModificaPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthPermessoUtenteModificaPost(params: ApiAuthPermessoUtenteModificaPost$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthPermessoUtenteModificaPost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthUtenteCorpoListGet()` */
    static readonly ApiAuthUtenteCorpoListGetPath = '/api/auth/utente-corpo-list';

    /**
     * Carica la lista degli utenti associati al corpo dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteCorpoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteCorpoListGet$Response(
        params?: ApiAuthUtenteCorpoListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteListItemDto>>> {
        return apiAuthUtenteCorpoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli utenti associati al corpo dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteCorpoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteCorpoListGet(params?: ApiAuthUtenteCorpoListGet$Params, context?: HttpContext): Observable<Array<UtenteListItemDto>> {
        return this.apiAuthUtenteCorpoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteListItemDto>>): Array<UtenteListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteUnioneListGet()` */
    static readonly ApiAuthUtenteUnioneListGetPath = '/api/auth/utente-unione-list';

    /**
     * Carica la lista degli utenti esterni associati ad una unione distrettuale.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteUnioneListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteUnioneListGet$Response(
        params?: ApiAuthUtenteUnioneListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteListItemDto>>> {
        return apiAuthUtenteUnioneListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli utenti esterni associati ad una unione distrettuale.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteUnioneListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteUnioneListGet(params?: ApiAuthUtenteUnioneListGet$Params, context?: HttpContext): Observable<Array<UtenteListItemDto>> {
        return this.apiAuthUtenteUnioneListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteListItemDto>>): Array<UtenteListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteFederazioneListGet()` */
    static readonly ApiAuthUtenteFederazioneListGetPath = '/api/auth/utente-federazione-list';

    /**
     * Carica la lista degli utenti esterni associati alla federazione.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteFederazioneListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteFederazioneListGet$Response(
        params?: ApiAuthUtenteFederazioneListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteListItemDto>>> {
        return apiAuthUtenteFederazioneListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli utenti esterni associati alla federazione.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteFederazioneListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteFederazioneListGet(
        params?: ApiAuthUtenteFederazioneListGet$Params,
        context?: HttpContext
    ): Observable<Array<UtenteListItemDto>> {
        return this.apiAuthUtenteFederazioneListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteListItemDto>>): Array<UtenteListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtentePermanenteListGet()` */
    static readonly ApiAuthUtentePermanenteListGetPath = '/api/auth/utente-permanente-list';

    /**
     * Carica la lista degli utenti esterni di tipo `Corpo Permamanente`.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtentePermanenteListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtentePermanenteListGet$Response(
        params?: ApiAuthUtentePermanenteListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteListItemDto>>> {
        return apiAuthUtentePermanenteListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli utenti esterni di tipo `Corpo Permamanente`.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtentePermanenteListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtentePermanenteListGet(
        params?: ApiAuthUtentePermanenteListGet$Params,
        context?: HttpContext
    ): Observable<Array<UtenteListItemDto>> {
        return this.apiAuthUtentePermanenteListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteListItemDto>>): Array<UtenteListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteAdministratorListGet()` */
    static readonly ApiAuthUtenteAdministratorListGetPath = '/api/auth/utente-administrator-list';

    /**
     * Carica la lista degli utenti esterni di tipo `Administrator`.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteAdministratorListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteAdministratorListGet$Response(
        params?: ApiAuthUtenteAdministratorListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteListItemDto>>> {
        return apiAuthUtenteAdministratorListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista degli utenti esterni di tipo `Administrator`.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteAdministratorListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteAdministratorListGet(
        params?: ApiAuthUtenteAdministratorListGet$Params,
        context?: HttpContext
    ): Observable<Array<UtenteListItemDto>> {
        return this.apiAuthUtenteAdministratorListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteListItemDto>>): Array<UtenteListItemDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteEsternoEditGet()` */
    static readonly ApiAuthUtenteEsternoEditGetPath = '/api/auth/utente-esterno-edit';

    /**
     * Carica i dati di un utente esterno per la modifica.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteEsternoEditGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteEsternoEditGet$Response(
        params: ApiAuthUtenteEsternoEditGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<UtenteEsternoEditDto>> {
        return apiAuthUtenteEsternoEditGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica i dati di un utente esterno per la modifica.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteEsternoEditGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteEsternoEditGet(params: ApiAuthUtenteEsternoEditGet$Params, context?: HttpContext): Observable<UtenteEsternoEditDto> {
        return this.apiAuthUtenteEsternoEditGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<UtenteEsternoEditDto>): UtenteEsternoEditDto => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteEsternoUpdatePatch()` */
    static readonly ApiAuthUtenteEsternoUpdatePatchPath = '/api/auth/utente-esterno-update';

    /**
     * Carica i dati di un utente esterno per la modifica.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteEsternoUpdatePatch()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUtenteEsternoUpdatePatch$Response(
        params: ApiAuthUtenteEsternoUpdatePatch$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiAuthUtenteEsternoUpdatePatch(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica i dati di un utente esterno per la modifica.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteEsternoUpdatePatch$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUtenteEsternoUpdatePatch(params: ApiAuthUtenteEsternoUpdatePatch$Params, context?: HttpContext): Observable<boolean> {
        return this.apiAuthUtenteEsternoUpdatePatch$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteAppartenenzaListGet()` */
    static readonly ApiAuthUtenteAppartenenzaListGetPath = '/api/auth/utente-appartenenza-list';

    /**
     * Carica la lista dei corpi\unioni visibili dall'utente censiti nel database della federazione e nel nostro portale.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteAppartenenzaListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteAppartenenzaListGet$Response(
        params: ApiAuthUtenteAppartenenzaListGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<UtenteAppartenenzaConFederazioneDto>>> {
        return apiAuthUtenteAppartenenzaListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei corpi\unioni visibili dall'utente censiti nel database della federazione e nel nostro portale.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteAppartenenzaListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteAppartenenzaListGet(
        params: ApiAuthUtenteAppartenenzaListGet$Params,
        context?: HttpContext
    ): Observable<Array<UtenteAppartenenzaConFederazioneDto>> {
        return this.apiAuthUtenteAppartenenzaListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<UtenteAppartenenzaConFederazioneDto>>): Array<UtenteAppartenenzaConFederazioneDto> => r.body)
        );
    }

    /** Path part for operation `apiAuthUtenteAppartenenzaUpdatePost()` */
    static readonly ApiAuthUtenteAppartenenzaUpdatePostPath = '/api/auth/utente-appartenenza-update';

    /**
     * Aggiorna l'appartenenza di un utente ai corpi, in aggiunta all'eventuale appartenza definita nel database della federazione.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteAppartenenzaUpdatePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUtenteAppartenenzaUpdatePost$Response(
        params: ApiAuthUtenteAppartenenzaUpdatePost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiAuthUtenteAppartenenzaUpdatePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiorna l'appartenenza di un utente ai corpi, in aggiunta all'eventuale appartenza definita nel database della federazione.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteAppartenenzaUpdatePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAuthUtenteAppartenenzaUpdatePost(params: ApiAuthUtenteAppartenenzaUpdatePost$Params, context?: HttpContext): Observable<void> {
        return this.apiAuthUtenteAppartenenzaUpdatePost$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiAuthUtenteDeleteDelete()` */
    static readonly ApiAuthUtenteDeleteDeletePath = '/api/auth/utente-delete';

    /**
     * Resetta la data di registrazione in modo che l'utente non possa più fare login.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAuthUtenteDeleteDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteDeleteDelete$Response(
        params?: ApiAuthUtenteDeleteDelete$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiAuthUtenteDeleteDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * Resetta la data di registrazione in modo che l'utente non possa più fare login.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAuthUtenteDeleteDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiAuthUtenteDeleteDelete(params?: ApiAuthUtenteDeleteDelete$Params, context?: HttpContext): Observable<boolean> {
        return this.apiAuthUtenteDeleteDelete$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }
}
