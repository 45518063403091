import { Injectable } from '@angular/core';
import { PermessoTokenDto } from '@core/http-gen/models/permesso-token-dto';
import { Permesso, PermessoValore } from '@shared/types/auth.models';

@Injectable({
    providedIn: 'root',
})
export class PermissionCheckerService {
    /*
  permisison: Rubrica, Selettive, etc etc
  permissionValore: nessuno, lettura, scrittura
  userPermission: rappresentazione in decimale dei permssi
  */

    hasThePermission(permisssion: Permesso, permissionValue: PermessoValore, userPermissions: Array<PermessoTokenDto>): boolean {
        if (!userPermissions || !userPermissions.length) {
            return false;
        }
        const permission = userPermissions.find((p) => p.p === permisssion);

        if (permission) {
            return !!((permission.v as number) & permissionValue);
        }

        return false;
    }
}
