/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReturnSelettivaDto } from '../../models/return-selettiva-dto';
import { SelettivaAddDto } from '../../models/selettiva-add-dto';

export interface ApiSelettivaTelegramPost$Params {
    /**
     * Dati della selettiva
     */
    body: SelettivaAddDto;
}

export function apiSelettivaTelegramPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiSelettivaTelegramPost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<ReturnSelettivaDto>> {
    const rb = new RequestBuilder(rootUrl, apiSelettivaTelegramPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<ReturnSelettivaDto>;
        })
    );
}

apiSelettivaTelegramPost.PATH = '/api/selettiva/telegram';
