import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { NgxAuthService } from 'ngx-auth-utils';
import { map } from 'rxjs/operators';
import { UtenteProfiloDto } from '@core/http-gen/models/utente-profilo-dto';
import { ToastService } from '@core/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
    //questa guardia da o meno accesso ad una rotta in base a se si ha permesso di lettura o meno

    readonly DEFAULT_REDIRECT_PATH = '/';

    constructor(
        private authenticationService: NgxAuthService,
        private router: Router,
        private permissionChecker: PermissionCheckerService,
        private toastService: ToastService
    ) {}
    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.checkConditionsAndRedirect(childRoute);
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.checkConditionsAndRedirect(route);
    }

    private checkConditionsAndRedirect(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.checkConditions(route).pipe(
            map((result) => {
                if (!result) {
                    this.toastService.error('Non hai i permessi per eseguire questa operazione.');
                    const localRedirectRoute = route?.data?.permissions?.redirectRoute;
                    if (localRedirectRoute !== false) {
                        if (localRedirectRoute != null) {
                            return this.router.parseUrl(localRedirectRoute);
                        }

                        return this.router.parseUrl(this.DEFAULT_REDIRECT_PATH);
                    }
                }

                return result;
            })
        );
    }

    private checkConditions(route: ActivatedRouteSnapshot | Route): Observable<boolean | UrlTree> {
        return this.authenticationService.state.pipe(
            map((user: UtenteProfiloDto | null) => {
                if (!user) {
                    if (isDevMode()) {
                        // eslint-disable-next-line no-console
                        console.warn(
                            'PermissionGuard should be used only combined with AuthUserGuard, ' +
                                'as it expects the user to be authenticated.'
                        );
                        throw new Error('user-not-authenticated');
                    }

                    return false;
                }

                if (!route.data || !route.data?.permissions) {
                    throw new Error('no-data');
                }

                return this.permissionChecker.hasThePermission(
                    route.data?.permissions.permission,
                    route.data?.permissions.permissionValue,
                    user.tokenData?.permessoList || []
                );
            })
        );
    }
}
