import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TagModule } from 'primeng/tag';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { FormButtonsComponent } from '@shared/forms/form-buttons/form-buttons.component';
import { FormFieldComponent } from '@shared/forms/form-field/form-field.component';
import { MenubarModule } from 'primeng/menubar';

import { TablePaginationComponent } from './table-pagination/table-pagination.component';

// Card box
import { ItemCardBoxComponent } from './card-box/item-card-box/item-card-box.component';
import { ListItemsCardBoxComponent } from './card-box/list-items-card-box/list-items-card-box.component';

@NgModule({
    declarations: [FormButtonsComponent, FormFieldComponent, TablePaginationComponent, ItemCardBoxComponent, ListItemsCardBoxComponent],
    imports: [CommonModule, MenubarModule, FontAwesomeModule, ButtonModule, RippleModule, TagModule, InputTextModule, FormsModule],
    exports: [FormButtonsComponent, FormFieldComponent, TablePaginationComponent, ItemCardBoxComponent, ListItemsCardBoxComponent],
})
export class SharedModule {}
