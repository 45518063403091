/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiTetraInformativaGet } from '../fn/tetra/api-tetra-informativa-get';
import { ApiTetraInformativaGet$Params } from '../fn/tetra/api-tetra-informativa-get';
import { apiTetraOperativaGet } from '../fn/tetra/api-tetra-operativa-get';
import { ApiTetraOperativaGet$Params } from '../fn/tetra/api-tetra-operativa-get';
import { TetraDto } from '../models/tetra-dto';

@Injectable({ providedIn: 'root' })
export class TetraService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiTetraInformativaGet()` */
    static readonly ApiTetraInformativaGetPath = '/api/tetra/informativa';

    /**
     * Ritorna l'ultima posizione nota all'interno dell'intervallo di tempo specificato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiTetraInformativaGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTetraInformativaGet$Response(
        params: ApiTetraInformativaGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<TetraDto>>> {
        return apiTetraInformativaGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Ritorna l'ultima posizione nota all'interno dell'intervallo di tempo specificato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiTetraInformativaGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTetraInformativaGet(params: ApiTetraInformativaGet$Params, context?: HttpContext): Observable<Array<TetraDto>> {
        return this.apiTetraInformativaGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<TetraDto>>): Array<TetraDto> => r.body)
        );
    }

    /** Path part for operation `apiTetraOperativaGet()` */
    static readonly ApiTetraOperativaGetPath = '/api/tetra/operativa';

    /**
     * Ritorna il percorso delle tetra all'interno dell'intervallo di tempo specificato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiTetraOperativaGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTetraOperativaGet$Response(
        params: ApiTetraOperativaGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<Array<TetraDto>>> {
        return apiTetraOperativaGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Ritorna il percorso delle tetra all'interno dell'intervallo di tempo specificato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiTetraOperativaGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTetraOperativaGet(params: ApiTetraOperativaGet$Params, context?: HttpContext): Observable<Array<TetraDto>> {
        return this.apiTetraOperativaGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<TetraDto>>): Array<TetraDto> => r.body)
        );
    }
}
