import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { LoadingService } from '@core/services/loading.service';
import { NgxAuthService } from 'ngx-auth-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastService } from '@core/services/toast.service';
import { AppConfig } from '@core/loaders/app-config.models';
import { ApiConfiguration } from '@core/http-gen/api-configuration';
import { UtenteProfiloDto } from '@core/http-gen/models';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'frontend';
    loading$: Observable<boolean>;

    public showHeader = true;

    constructor(
        private appConfig: AppConfig,
        private primengConfig: PrimeNGConfig,
        private loadingService: LoadingService,
        private authenticationService: NgxAuthService,
        private apiConfiguration: ApiConfiguration,
        private toastService: ToastService
    ) {
        this.primengConfig.ripple = true;

        // Imposto la root dei servizi con il valore letto dal file di configurazione
        this.apiConfiguration.rootUrl = appConfig.rootUrl;

        this.loading$ = this.loadingService.loading$;
        this.authenticationService.initialize().subscribe();

        this.authenticationService.events.pipe(untilDestroyed(this)).subscribe((authEvent) => {
            if (authEvent.type === 'guard-blocked-access') {
                this.toastService.error('Non hai i permessi per accedere a queste sezione');
            }
        });

        this.authenticationService.state.pipe(untilDestroyed(this)).subscribe((user: UtenteProfiloDto | null) => {
            if (!user) {
                this.showHeader = false;
            } else {
                this.showHeader = true;
            }
        });
    }
}
