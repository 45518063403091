import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';

import { EMPTY } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { catchError, finalize, take } from 'rxjs/operators';
import { TreeTableNodeUnSelectEvent } from 'primeng/treetable';
import { ConfirmationService, Message, MessageService, TreeNode, TreeTableNode } from 'primeng/api';

import { NodeSelectEvent } from '@shared/types/primeng.types';
import { ToastService } from '@core/services/toast.service';
import { LoadingService } from '@core/services/loading.service';
import { confirmDialogConfig } from '@core/utils/dialog-config';
import {
    DestinatarioNotificaDto,
    GradoCountDto,
    InvioNotificaDto,
    MansioneCountDto,
    VvfVolontarioGradoDto,
    VvfVolontarioMansioneDto,
} from '@core/http-gen/models';
import { NotificaService, OrganicoService } from '@core/http-gen/services';
import { SelezioneByGrado, SelezioneByMansione, SelezioneTree, TipoSelezioneGrado, TipoSelezioneMansione } from './invio-notifiche.models';

@Component({
    selector: 'app-invio-notifiche',
    templateUrl: './invio-notifiche.component.html',
    styleUrls: ['./invio-notifiche.component.scss'],
})
export class InvioNotificheComponent implements OnInit {
    @ViewChild('allegatiUploader') allegatiUploader!: FileUpload;
    public gradoTreeList: Array<TreeNode> = [];
    public mansioneTreeList: Array<TreeNode> = [];

    public tipoSelezioneGrado = TipoSelezioneGrado;
    public tipoSelezioneMansione = TipoSelezioneMansione;

    public selectedGradoNodes: Array<TreeNode> = [];
    public selectedMansioneNodes: Array<TreeNode> = [];

    loadingGradi = true;
    loadingMansioni = true;

    public invioNotificaForm: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _organicoService: OrganicoService,
        private _messageService: MessageService,
        private _confirmationService: ConfirmationService,
        private _loadingService: LoadingService,
        private _notificaService: NotificaService,
        private _toastService: ToastService,
    ) {
        this.invioNotificaForm = this._formBuilder.group({
            titolo: this._formBuilder.control(''),
            messaggio: this._formBuilder.control('', [Validators.required]),
        });
    }

    ngOnInit(): void {
        // Carica la lista dei gradi presenti nel database federazione
        this._organicoService
            .apiOrganicoGradoListGet()
            .pipe(take(1))
            .subscribe((gradoList: Array<GradoCountDto>) => {
                const treeNodeList: Array<TreeNode> = [];
                gradoList.forEach((grado: GradoCountDto) => {
                    // Aggiungo i gradi alla lista solamente se hanno dei componenti
                    if (grado.count > 0) {
                        const treeNode: TreeNode = {
                            label: grado.descrizione,
                            data: {
                                tipoSelezioneGrado: TipoSelezioneGrado.grado,
                                grado: grado,
                            } as SelezioneByGrado,
                            leaf: false,
                        } as TreeNode;

                        treeNodeList.push(treeNode);
                    }
                });

                this.gradoTreeList = treeNodeList;
                this.loadingGradi = false;
            });

        // Carica la lista delle mansioni presenti nel database federazione
        this._organicoService
            .apiOrganicoMansioneListGet()
            .pipe(take(1))
            .subscribe((mansioneList: Array<MansioneCountDto>) => {
                const treeNodeList: Array<TreeNode> = [];
                mansioneList.forEach((mansione: MansioneCountDto) => {
                    // Aggiungo le mansioni alla lista solamente se hanno dei componenti
                    if (mansione.count > 0) {
                        const treeNode: TreeNode = {
                            label: mansione.descrizione,
                            data: {
                                tipoSelezioneMansione: TipoSelezioneMansione.mansione,
                                mansione: mansione,
                            } as SelezioneByMansione,
                            leaf: false,
                        } as TreeNode;

                        treeNodeList.push(treeNode);
                    }
                });

                this.mansioneTreeList = treeNodeList;
                this.loadingMansioni = false;
            });
    }

    /** Metodo richiamato all'espansione di un nodo della lista contenente i vigili suddivisi per grado */
    public loadGradoNode(event: NodeSelectEvent): void {
        if (event.node) {
            const selezioneByGrado: SelezioneByGrado = event.node.data;
            const gradoDto: GradoCountDto = selezioneByGrado.grado ?? ({} as GradoCountDto);
            const children: Array<TreeNode> = [];

            // carico la lista dei vigili che hanno il grado che si sta espandendo
            this._organicoService
                .apiOrganicoVolontarioListByGradoGet({ gradoId: gradoDto.idGrado })
                .pipe(take(1))
                .subscribe((volontarioList: Array<VvfVolontarioGradoDto>) => {
                    // Aggiunge i volontari caricati come figli del nodo aperto
                    volontarioList.forEach((volontarioGrado: VvfVolontarioGradoDto) => {
                        const treeNode: TreeNode = {
                            label: volontarioGrado.cognomePersona,
                            data: {
                                tipoSelezioneGrado: TipoSelezioneGrado.volontario,
                                volontario: volontarioGrado,
                            } as SelezioneByGrado,
                        } as TreeNode;
                        children.push(treeNode);

                        if (selezioneByGrado.selezionato) {
                            // Se il nodo padre è selezionato, imposto selezionato anche il figlio
                            this.selectedGradoNodes.push(treeNode);
                        }
                    });
                    // Assegno i nodi al nodo padre
                    event.node.children = children;
                    // Riassegno la lista in modo che i nodi vengano ricaricati
                    this.gradoTreeList = [...this.gradoTreeList];
                });
        }
    }

    /** Metodo richiamato all'espansione di un nodo della lista contenente i vigili suddivisi per mansione */
    public loadMansioneNode(event: NodeSelectEvent): void {
        if (event.node) {
            const selezioneByMansione: SelezioneByMansione = event.node.data;
            const mansioneDto: MansioneCountDto = selezioneByMansione.mansione ?? ({} as MansioneCountDto);
            const children: Array<TreeNode> = [];

            // carico la lista dei vigili che hanno la mansione che si sta espandendo
            this._organicoService
                .apiOrganicoVolontarioListByMansioneGet({ mansioneId: mansioneDto.idMansione })
                .pipe(take(1))
                .subscribe((volontarioList: Array<VvfVolontarioMansioneDto>) => {
                    // Aggiunge i volontari caricati come figli del nodo aperto
                    volontarioList.forEach((volontarioMansione: VvfVolontarioMansioneDto) => {
                        const treeNode: TreeNode = {
                            label: volontarioMansione.cognomePersona,
                            data: {
                                tipoSelezioneMansione: TipoSelezioneMansione.volontario,
                                volontario: volontarioMansione,
                            } as SelezioneByMansione,
                        } as TreeNode;
                        children.push(treeNode);

                        if (selezioneByMansione.selezionato) {
                            // Se il nodo padre è selezionato, imposto selezionato anche il figlio
                            this.selectedMansioneNodes.push(treeNode);
                        }
                    });
                    // Assegno i nodi al nodo padre
                    event.node.children = children;
                    // Riassegno la lista in modo che i nodi vengano ricaricati
                    this.mansioneTreeList = [...this.mansioneTreeList];
                });
        }
    }

    /** Metodo richiamato alla selezione di un nodo della lista */
    public nodeSelect(event: TreeTableNode<SelezioneTree>): void {
        event.data.selezionato = true;
    }

    /** Metodo richiamato alla deselezione di un nodo della lista */
    public nodeUnselect(event: TreeTableNodeUnSelectEvent): void {
        (event.node!.data as SelezioneTree).selezionato = false;
    }

    /** Metodo richimato al click sul pulsante di invio della notifica */
    public inviaNotifica(): void {
        const destinatarioNotificaList: Array<DestinatarioNotificaDto> = this._getDestinatari();
        if (!destinatarioNotificaList.length) {
            // Se non ho selezionato nessun destinatario restituisco subito un messaggio ed esco
            this._messageService.add({
                severity: 'error',
                summary: `Non inviata`,
                detail: 'Per inviare una notifica è necessario selezionare almeno un destinatario',
            } as Message);
        } else {
            const notifica: InvioNotificaDto = Object.assign(
                { destinatarioList: destinatarioNotificaList },
                this.invioNotificaForm.value,
            ) as InvioNotificaDto;

            if (this.allegatiUploader._files) {
                // Se sono stati selezionati degli allegati li aggiungo al modello che veràà inviato alle API
                notifica.allegatoList = [];
                this.allegatiUploader._files.forEach((file: File) => {
                    notifica.allegatoList?.push(file);
                });
            }

            this._confirmationService.confirm({
                message: `Stai per inviare una notifica a ${destinatarioNotificaList.length}  utenti, continuare?`,
                header: 'Invio notifica',
                acceptLabel: 'Invia',
                rejectLabel: 'Annulla',
                icon: 'pi pi-exclamation-triangle',
                ...confirmDialogConfig,
                accept: () => {
                    this._loadingService.show();
                    // eslint-disable-next-line no-console
                    console.log('Sending: ', notifica);

                    this._notificaService
                        .apiNotificaInvioNotificaPost({ body: notifica })
                        .pipe(
                            catchError(() => {
                                this._toastService.error("Errore nell'invio della notifica");
                                return EMPTY;
                            }),
                            finalize(() => {
                                this._loadingService.hide();
                                this.invioNotificaForm.reset();
                            }),
                        )
                        .subscribe(() => {
                            this._toastService.success('Notifica inviata');
                        });
                },
            });
        }
    }

    /** Estrae il destinatario della notifica dai dati di un nodo */
    private _getDestinatarioFromTreeNodeData(data: SelezioneByGrado | SelezioneByMansione): DestinatarioNotificaDto {
        let destinatarioNotificaDto: DestinatarioNotificaDto;
        if ((<SelezioneByGrado>data).tipoSelezioneGrado) {
            // Se si tratta di una selezione per grado, converto l'oggetto
            const selezioneByGrado: SelezioneByGrado = data as SelezioneByGrado;
            if (selezioneByGrado.tipoSelezioneGrado === TipoSelezioneGrado.grado) {
                destinatarioNotificaDto = {
                    destinatarioTipo: 1, //DestinatarioTipo.grado,
                    idEntita: selezioneByGrado.grado?.idGrado,
                } as DestinatarioNotificaDto;
            } else {
                destinatarioNotificaDto = {
                    destinatarioTipo: 10, //DestinatarioTipo.volontario,
                    idEntita: selezioneByGrado.volontario?.idVolontario,
                } as DestinatarioNotificaDto;
            }
        } else {
            // Se si tratta di una selezione per mansione, converto l'oggetto
            const selezioneByMansione: SelezioneByMansione = data as SelezioneByMansione;
            if (selezioneByMansione.tipoSelezioneMansione === TipoSelezioneMansione.mansione) {
                destinatarioNotificaDto = {
                    destinatarioTipo: 2, //DestinatarioTipo.mansione,
                    idEntita: selezioneByMansione.mansione?.idMansione,
                } as DestinatarioNotificaDto;
            } else {
                destinatarioNotificaDto = {
                    destinatarioTipo: 10, //DestinatarioTipo.volontario,
                    idEntita: selezioneByMansione.volontario?.idVolontario,
                } as DestinatarioNotificaDto;
            }
        }
        return destinatarioNotificaDto;
    }

    /** Scorre la lista dei nodi selezionati, ed estrae i destinatari della notifica */
    private _getDestinatari(): Array<DestinatarioNotificaDto> {
        const destinatarioNotificaList: Array<DestinatarioNotificaDto> = [];
        this.selectedGradoNodes.forEach((treeNode: TreeNode) => {
            if ((treeNode.data as SelezioneTree).selezionato) {
                // Significa che ho selezionato tutto il gruppo per l'invio
                const destinatarioNotificaDto: DestinatarioNotificaDto = this._getDestinatarioFromTreeNodeData(treeNode.data);

                // Se il grado o la mansione non è già stato aggiunto alla lista, lo aggiungo
                if (
                    !destinatarioNotificaList.find(
                        (destinatario: DestinatarioNotificaDto) =>
                            destinatario.destinatarioTipo === destinatarioNotificaDto.destinatarioTipo &&
                            destinatario.idEntita === destinatarioNotificaDto.idEntita,
                    )
                ) {
                    destinatarioNotificaList.push(destinatarioNotificaDto);
                }
            } else {
                if (treeNode.parent && (treeNode.parent.data as SelezioneTree).selezionato) {
                    // Se il parent è selezionato, allora lo aggiungo alla lista dei selezionati
                    const destinatarioNotificaDto: DestinatarioNotificaDto | null = this._getDestinatarioFromTreeNodeData(
                        treeNode.parent.data,
                    );

                    // Se il grado o la mansione non è già stato aggiunto alla lista, lo aggiungo
                    if (
                        !destinatarioNotificaList.find(
                            (destinatario: DestinatarioNotificaDto) =>
                                destinatario.destinatarioTipo === destinatarioNotificaDto.destinatarioTipo &&
                                destinatario.idEntita === destinatarioNotificaDto.idEntita,
                        )
                    ) {
                        destinatarioNotificaList.push(destinatarioNotificaDto);
                    }
                } else {
                    // In questo caso, aggiungo direttamente il volontario
                    const destinatarioNotificaDto: DestinatarioNotificaDto | null = this._getDestinatarioFromTreeNodeData(treeNode.data);

                    // Aggiungo il volontario solamente se non è già presente
                    if (
                        !destinatarioNotificaList.find(
                            (destinatario: DestinatarioNotificaDto) =>
                                destinatario.destinatarioTipo === destinatarioNotificaDto.destinatarioTipo &&
                                destinatario.idEntita === destinatarioNotificaDto.idEntita,
                        )
                    ) {
                        destinatarioNotificaList.push(destinatarioNotificaDto);
                    }
                }
            }
        });

        return destinatarioNotificaList;
    }
}
