/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UtenteAppartenenzaConFederazioneDto } from '../../models/utente-appartenenza-con-federazione-dto';

export interface ApiAuthUtenteAppartenenzaUpdatePost$Params {
    /**
     * Id dell'utente per cui modificare l'appartenenza ai corpi
     */
    utenteId: number;

    /**
     * Lista dell'appartenenza dell'utente ai corpi\unioni
     */
    body: Array<UtenteAppartenenzaConFederazioneDto>;
}

export function apiAuthUtenteAppartenenzaUpdatePost(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthUtenteAppartenenzaUpdatePost$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiAuthUtenteAppartenenzaUpdatePost.PATH, 'post');
    if (params) {
        rb.query('utenteId', params.utenteId, {});
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiAuthUtenteAppartenenzaUpdatePost.PATH = '/api/auth/utente-appartenenza-update';
