import { GradoCountDto, MansioneCountDto, VvfVolontarioGradoDto, VvfVolontarioMansioneDto } from '@core/http-gen/models';

export interface SelezioneTree {
    selezionato: boolean;
}

export interface SelezioneByGrado extends SelezioneTree {
    tipoSelezioneGrado: TipoSelezioneGrado;

    grado?: GradoCountDto;
    volontario?: VvfVolontarioGradoDto;
}

export enum TipoSelezioneGrado {
    grado = 1,
    volontario = 2,
}

export interface SelezioneByMansione extends SelezioneTree {
    tipoSelezioneMansione: TipoSelezioneMansione;

    mansione?: MansioneCountDto;
    volontario?: VvfVolontarioMansioneDto;
}

export enum TipoSelezioneMansione {
    mansione = 1,
    volontario = 2,
}
