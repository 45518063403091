import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppTypeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Skip radar-api.protezionecivile.it
        if (req.url.indexOf('https://radar-api.protezionecivile.it') !== -1) {
            return next.handle(req);
        }

        const modifiedReq = req.clone({
            setHeaders: {
                'app-type': 'web',
            },
        });

        // Pass on the cloned request instead of the original request
        return next.handle(modifiedReq);
    }
}
