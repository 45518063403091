/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiAuthUtenteDeleteDelete$Params {
    /**
     * Id dell'utente da eliminare, se non specificato viene eliminato l'utente che fa la richiesta
     */
    utenteId?: number;
}

export function apiAuthUtenteDeleteDelete(
    http: HttpClient,
    rootUrl: string,
    params?: ApiAuthUtenteDeleteDelete$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(rootUrl, apiAuthUtenteDeleteDelete.PATH, 'delete');
    if (params) {
        rb.query('utenteId', params.utenteId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({
                body: String((r as HttpResponse<any>).body) === 'true',
            }) as StrictHttpResponse<boolean>;
        })
    );
}

apiAuthUtenteDeleteDelete.PATH = '/api/auth/utente-delete';
