import { NgModule } from '@angular/core';
import { LoadChildren, RouterModule, Routes } from '@angular/router';

import { AnonUserGuard, AuthUserGuard, UserResolver } from 'ngx-auth-utils';

import { RoleGuard } from '@shared/roles/role.guard';
import { FooterComponent } from '@core/ui/footer/footer.component';
import { HeaderComponent } from '@core/ui/header/header.component';
import { Permesso, PermessoValore } from '@shared/types/auth.models';
import { PermissionGuard } from '@shared/permissions/permission.guard';
import { MappaComponent } from '@modules/dashboard/mappa/mappa.component';
import { IdrantiComponent } from '@modules/dashboard/idranti/idranti.component';
import { ScadenzeComponent } from '@modules/dashboard/scadenze/scadenze.component';
import { AlertcallsComponent } from './modules/alertcalls/alertcalls/alertcalls.component';
import { AdminPanelComponent } from '@modules/admin-panel/admin-panel/admin-panel.component';
import { NumeriUtiliComponent } from '@modules/dashboard/numeri-utili/numeri-utili.component';
import { displaySelettiveComponent } from '@modules/display-selettive/display-selettive.component';
import { TipoUtente } from '@modules/gestione-utenti/nuovo-utente-esterno/nuovo-utente-esterno.models';
import { InvioNotificheComponent } from '@modules/dashboard/invio-notifiche/invio-notifiche.component';
import { NotFoundComponentComponent } from './modules/not-found-component/not-found-component.component';
import { MonitorCentraleComponent } from '@modules/dashboard/monitor-centrale/monitor-centrale.component';
import { displaySelettiveFullComponent } from '@modules/display-selettive-full/display-selettive-full.component';

import { ChangelogComponent } from './modules/dashboard/changelog/changelog.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: (() => import('./modules/auth/auth.module').then((m) => m.AuthModule)) as LoadChildren,
        canActivate: [AnonUserGuard],
    },
    {
        path: 'test-page',
        loadChildren: (() => import('./modules/test-page/test-page.module').then((m) => m.TestPageModule)) as LoadChildren,
    },
    {
        path: 'admin-panel',
        canActivate: [AuthUserGuard],
        children: [
            {
                path: '',
                loadChildren: (() => import('./modules/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule)) as LoadChildren,
            },
            {
                path: '',
                pathMatch: 'full',
                component: AdminPanelComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [RoleGuard],
                data: {
                    TipoUtente: TipoUtente.Administrator,
                },
            },
        ],
    },
    {
        path: 'alertcalls',
        canActivate: [AuthUserGuard],
        children: [
            {
                path: '',
                loadChildren: (() => import('./modules/alertcalls/alertcalls.module').then((m) => m.AlertCallsModule)) as LoadChildren,
            },
            {
                path: '',
                pathMatch: 'full',
                component: AlertcallsComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.StoricoSelettive,
                        permissionValue: PermessoValore.Lettura,
                        redirectRoute: '/',
                    },
                },
            },
            {
                path: 'create',
                pathMatch: 'full',
                component: AlertcallsComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.StoricoSelettive,
                        permissipermissionValue: PermessoValore.Lettura, // TODO: Cambiare in scrittura
                        redirectRoute: '/',
                    },
                },
            },
        ],
    },
    {
        path: '',
        canActivateChild: [AuthUserGuard],
        children: [
            {
                path: '',
                loadChildren: (() => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)) as LoadChildren,
            },
            {
                path: 'changelog',
                pathMatch: 'full',
                canActivate: [AuthUserGuard],
                component: ChangelogComponent,
            },
            {
                path: 'contatti',
                pathMatch: 'full',
                component: NumeriUtiliComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.RubricaTelefonica,
                        permissionValue: PermessoValore.Lettura,
                        redirectRoute: '/',
                    },
                },
            },
            {
                path: 'monitor-selettive', // This path is not used, it is only for old monitor-selettive
                pathMatch: 'full',
                redirectTo: '/display-selettive-full',
            },
            {
                path: 'new-monitor-selettive', // This path is not used, it is only for old monitor-selettive
                pathMatch: 'full',
                redirectTo: '/display-selettive-full',
            },
            {
                path: 'display-selettive',
                pathMatch: 'full',
                component: displaySelettiveComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.MonitorSelettive,
                        permissionValue: PermessoValore.Lettura,
                        redirectRoute: '/',
                    },
                },
            },
            {
                path: 'display-selettive-full',
                pathMatch: 'full',
                component: displaySelettiveFullComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.MonitorSelettive,
                        permissionValue: PermessoValore.Lettura,
                        redirectRoute: '/',
                    },
                },
            },
            {
                path: 'monitor-centrale',
                pathMatch: 'full',
                component: MonitorCentraleComponent,
                resolve: {
                    user: UserResolver,
                },
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.MonitorCentrale,
                        permissionValue: PermessoValore.Lettura,
                        redirectRoute: '/',
                    },
                },
            },
            {
                path: 'mappa',
                pathMatch: 'full',
                component: MappaComponent,
                resolve: {
                    user: UserResolver,
                },
            },
            {
                path: 'idranti',
                pathMatch: 'full',
                component: IdrantiComponent,
                resolve: {
                    user: UserResolver,
                },
            },
            {
                path: 'invio-notifiche',
                pathMatch: 'full',
                component: InvioNotificheComponent,
                resolve: {
                    user: UserResolver,
                },
            },
            {
                path: 'scadenze-lista',
                pathMatch: 'full',
                component: ScadenzeComponent,
                resolve: {
                    user: UserResolver,
                },
            },
            {
                path: 'gestione-utenti',
                loadChildren: (() =>
                    import('./modules/gestione-utenti/gestione-utenti.module').then((m) => m.GestioneUtentiModule)) as LoadChildren,
            },
            {
                path: 'gestione-ruoli',
                loadChildren: (() =>
                    import('./modules/gestione-ruoli/gestione-ruoli.module').then((m) => m.GestioneRuoliModule)) as LoadChildren,
                /*resolve: {
    user: UserResolver,
},*/
                canActivate: [PermissionGuard],
                data: {
                    permissions: {
                        permission: Permesso.GestioneRuoli,
                        permissionValue: PermessoValore.Lettura,
                    },
                },
            },
        ],
    },
    {
        path: '',
        component: HeaderComponent,
        outlet: 'header',
    },
    {
        path: '',
        component: FooterComponent,
        outlet: 'footer',
    },
    {
        path: '404',
        component: NotFoundComponentComponent,
    },
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
