/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiNotificaNotificaAllegatoByIdGet$Params {
    /**
     * Id dell'allegato da scaricare
     */
    allegatoId: number;
}

export function apiNotificaNotificaAllegatoByIdGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiNotificaNotificaAllegatoByIdGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(rootUrl, apiNotificaNotificaAllegatoByIdGet.PATH, 'get');
    if (params) {
        rb.query('allegatoId', params.allegatoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<{}>;
        })
    );
}

apiNotificaNotificaAllegatoByIdGet.PATH = '/api/notifica/notifica-allegato-by-id';
