import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastMessage {
    status: 'success' | 'danger' | 'warning' | 'info';
    header: string;
    body: string;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastSubject = new Subject<ToastMessage>();
    toastState = this.toastSubject.asObservable();

    public success(body: string): void {
        this.toastSubject.next({ status: 'success', header: 'Successo', body });
    }

    public error(body: string): void {
        this.toastSubject.next({ status: 'danger', header: 'Errore', body });
    }

    public warning(body: string): void {
        this.toastSubject.next({ status: 'warning', header: 'Attenzione', body });
    }

    public info(body: string): void {
        this.toastSubject.next({ status: 'info', header: 'Avviso', body });
    }
}
