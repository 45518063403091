<div class="container">
    <div class="wrapper--shadow bg-white p-p-2">
        <p-table
            #dt
            [value]="idranti"
            [columns]="columns"
            [rowHover]="true"
            styleClass="p-datatable-gridlines fs-13"
            [lazy]="false"
            (onLazyLoad)="loadIdranti()"
            [autoLayout]="true"
            [filterDelay]="0"
            [globalFilterFields]="fields"
            [loading]="loadingList"
            editMode="row"
            dataKey="id"
        >
            <ng-template pTemplate="caption">
                <div class="table-header">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Cerca..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width" />
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        <div class="flex align-center">
                            {{ col.header }}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-idrante let-editing="editing" let-ri="rowIndex" let-columns="columns">
                <tr [pEditableRow]="idrante">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.field">
                            <span *ngSwitchCase="'nome'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="idrante.nome" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ idrante.nome }}
                                    </ng-template>
                                </p-cellEditor>
                            </span>
                            <span *ngSwitchCase="'x'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="idrante.posizione.x" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ idrante.posizione.x }}
                                    </ng-template>
                                </p-cellEditor>
                            </span>
                            <span *ngSwitchCase="'y'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="idrante.posizione.y" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ idrante.posizione.y }}
                                    </ng-template>
                                </p-cellEditor>
                            </span>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">Nessun idrante</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
