/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MansioneCountDto } from '../../models/mansione-count-dto';

export interface ApiOrganicoMansioneListGet$Params {
    /**
     * Id dell'unione per cui caricare i volontari
     */
    unioneId?: number;

    /**
     * Id del corpo per cui caricare i volontari
     */
    corpoId?: number;
}

export function apiOrganicoMansioneListGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiOrganicoMansioneListGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<MansioneCountDto>>> {
    const rb = new RequestBuilder(rootUrl, apiOrganicoMansioneListGet.PATH, 'get');
    if (params) {
        rb.query('unioneId', params.unioneId, {});
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<MansioneCountDto>>;
        })
    );
}

apiOrganicoMansioneListGet.PATH = '/api/organico/mansione-list';
