/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PermessoUtenteModificaDto } from '../../models/permesso-utente-modifica-dto';

export interface ApiAuthPermessoUtenteModificaListGet$Params {
    /**
     * Id dell'utente per cui caricare i permessi
     */
    utenteId: number;

    /**
     * Id del corpo per cui caricare i permessi dell'utente
     */
    corpoId?: number;
}

export function apiAuthPermessoUtenteModificaListGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthPermessoUtenteModificaListGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<PermessoUtenteModificaDto>>> {
    const rb = new RequestBuilder(rootUrl, apiAuthPermessoUtenteModificaListGet.PATH, 'get');
    if (params) {
        rb.query('utenteId', params.utenteId, {});
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<PermessoUtenteModificaDto>>;
        })
    );
}

apiAuthPermessoUtenteModificaListGet.PATH = '/api/auth/permesso-utente-modifica-list';
