import { Component, OnInit, ViewChild } from '@angular/core';
import { MarcatoreDto } from '@core/http-gen/models/marcatore-dto';
import { MarcatoreService } from '@core/http-gen/services/marcatore.service';
import { Table } from 'primeng/table/table';

@Component({
    selector: 'app-idranti',
    templateUrl: './idranti.component.html',
    styleUrls: ['./idranti.component.scss'],
})
export class IdrantiComponent implements OnInit {
    idranti: Array<MarcatoreDto> = [];
    @ViewChild('dt') table!: Table;
    columns = [
        { field: 'nome', header: 'Nome' },
        { field: 'x', header: 'Latitudine' },
        { field: 'y', header: 'Longitudine' },
    ];
    fields: string[] = [];
    addingNew = false;
    saving = false;
    loadingList = true;

    constructor(private idrantiService: MarcatoreService) {}

    loadIdranti(): void {
        this.loadingList = true;
        this.idrantiService
            .apiMarcatoreIdranteListByPosizionePost({
                body: {
                    topLeftPoint: {
                        x: 10.519868,
                        y: 46.452181,
                    },
                    topRightPoint: {
                        x: 12.119087,
                        y: 46.576185,
                    },
                    bottomLeftPoint: {
                        x: 10.281776,
                        y: 45.733835,
                    },
                    bottomRightPoint: {
                        x: 11.523124,
                        y: 45.618032,
                    },
                },
            })
            .subscribe((idranti) => {
                this.idranti = idranti;
                this.loadingList = false;
            });
    }

    ngOnInit(): void {
        this.fields = this.columns.map((col) => col.field);
        this.loadIdranti();
    }
}
