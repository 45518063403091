/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CorpoListItemDto } from '../../models/corpo-list-item-dto';

export interface ApiOrganicoCorpoListGet$Params {
    /**
     * Id dell'unione per cui caricare i corpi
     */
    unioneId?: number;
}

export function apiOrganicoCorpoListGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiOrganicoCorpoListGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<Array<CorpoListItemDto>>> {
    const rb = new RequestBuilder(rootUrl, apiOrganicoCorpoListGet.PATH, 'get');
    if (params) {
        rb.query('unioneId', params.unioneId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<CorpoListItemDto>>;
        })
    );
}

apiOrganicoCorpoListGet.PATH = '/api/organico/corpo-list';
