import { Injectable } from '@angular/core';
import { interval, Observable, ReplaySubject } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _loading$ = new ReplaySubject<boolean>();
    private counter = 0;

    public show(): void {
        if (this.counter === 0) {
            this._loading$.next(true);
        }
        this.counter += 1;
    }

    public hide(force = false): void {
        if (force) {
            this.counter = 0;
        } else {
            this.counter = Math.max(this.counter - 1, 0);
        }

        if (this.counter === 0) {
            this._loading$.next(false);
        }
    }

    public get loading$(): Observable<boolean> {
        return this._loading$.asObservable().pipe(debounce(() => interval(250)));
    }
}
