import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';

import { filter, switchMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';

export class ConfigReaderService<TConfig> {
    public get config(): TConfig | null {
        return this._$config.value;
    }
    public set config(value: TConfig | null) {
        this._$config.next(value);
    }

    protected _$config = new BehaviorSubject<TConfig | null>(null);
    public $config = this._$config.asObservable();

    constructor(
        protected httpClient: HttpClient,
        protected configUri: string,
        protected configType: unknown,
        protected injector: Injector
    ) {}

    public read(): Promise<void> {
        return new Promise((resolve) => {
            const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                this.beforeGetRequest()
                    .pipe(
                        filter((result) => result),
                        switchMap(() => this.httpClient.get<TConfig>(this.configUri))
                    )
                    .subscribe((config) => {
                        this.config = config;
                        resolve();
                    });
            });
        });
    }

    /** Event called before start GET request */
    protected beforeGetRequest(): Observable<boolean> {
        return of(true);
    }
}
