<div class="container-xxl">
    <div class="mt-3">
        <div class="row">
            <h2 class="col-12 col-md-7 my-auto text-start">Pannello Admin</h2>
        </div>
    </div>

    <div class="mt-3">
        <h4>Azioni Rapide</h4>
        <div class="grid gap-2 m-0">
            <a href="/gestione-utenti" class="p-2 g-col bg-white border rounded text-black">
                <i class="bi bi-people me-2"></i> Gestione Utenti
            </a>
            <a href="/gestione-ruoli" class="p-2 g-col bg-white border rounded text-black">
                <i class="bi bi-person-video3 me-2"></i> Gestione Ruoli
            </a>
        </div>
    </div>

    <div class="card mt-3">
        <div class="card-header">
            <h5 class="card-title m-0">Ultime sessioni online</h5>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Utente</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Piattaforma</th>
                            <th scope="col">Ultima volta online</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let session of usersOnlineList">
                            <td>{{ getFullName(session.user) }}</td>
                            <td>{{ getUserType(session.user.tipoUtente) }}</td>
                            <td>{{ getSessionType(session.type) }}</td>
                            <td>{{ session.lastTimeUsedAt | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <a href="/admin-panel/sessions" class="link-primary">Vedi tutte le sessioni</a>
        </div>
    </div>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="card-title m-0">Selettive Catturate</h5>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Corpo</th>
                            <th scope="col">Data</th>
                            <th scope="col">Evento</th>
                            <th scope="col">Dettaglio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let alert of alertCallsList">
                            <td>{{ alert.corpo["CRP_DESCRIZIONE"].toUpperCase() || alert.corpoId }}</td>
                            <td>{{ alert.dataOra | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                            <td>{{ alert.eventoTipologia }}</td>
                            <td>{{ alert.eventoDettaglio }}</td>
                            <td>
                                <a href="/alertcalls/{{ alert.id }}" class="link-primary"> Apri Selettiva</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <a href="/alertcalls" class="link-primary">Vedi tutte le selettive</a>
        </div>
    </div>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="card-title m-0">Notifiche Push Inviate</h5>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Utente</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Titolo</th>
                            <th scope="col">Inviata</th>
                            <th scope="col">Ricevuta</th>
                            <th scope="col">Letta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notification of pushNotificationsList">
                            <td>{{ getFullName(notification.user) }}</td>
                            <td
                                class="{{
                                    notification.entryType === 99 ? 'text-danger' : notification.entryType === 98 ? 'text-warning' : ''
                                }}"
                            >
                                {{ getPushNotificationType(notification.entryType) }}
                            </td>
                            <td>{{ notification.pushNotificationTitle }}</td>
                            <td>{{ notification.createdAt | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                            <td>{{ notification.receivedAt | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                            <td>{{ notification.readAt | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <a href="/admin-panel/push-notifications" class="link-primary">Vedi tutte le notifiche push</a>
        </div>
    </div>
</div>
