import { Component } from '@angular/core';
import { Map } from 'ol';

@Component({
    selector: 'app-mappa',
    templateUrl: './mappa.component.html',
    styleUrls: ['./mappa.component.scss'],
})
export class MappaComponent {
    public onMapReady(event: Map): void {
        // eslint-disable-next-line no-console
        console.log('Map Ready:', event);
    }
}
