/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiPushnotificationsCountGet } from '../fn/push-notification/api-pushnotifications-count-get';
import { ApiPushnotificationsCountGet$Params } from '../fn/push-notification/api-pushnotifications-count-get';
import { apiPushnotificationsGet } from '../fn/push-notification/api-pushnotifications-get';
import { ApiPushnotificationsGet$Params } from '../fn/push-notification/api-pushnotifications-get';
import { DefaultMultiRecordReturnDto } from '../models/default-multi-record-return-dto';
import { DefaultSingleRecordReturnDto } from '../models/default-single-record-return-dto';

@Injectable({ providedIn: 'root' })
export class PushNotificationService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiPushnotificationsGet()` */
    static readonly ApiPushnotificationsGetPath = '/api/pushnotifications';

    /**
     * Get all push notifications.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsGet$Response(
        params?: ApiPushnotificationsGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiPushnotificationsGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get all push notifications.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsGet(params?: ApiPushnotificationsGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiPushnotificationsGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiPushnotificationsCountGet()` */
    static readonly ApiPushnotificationsCountGetPath = '/api/pushnotifications/count';

    /**
     * Get the count of push notifications.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsCountGet$Response(
        params?: ApiPushnotificationsCountGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiPushnotificationsCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get the count of push notifications.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsCountGet(
        params?: ApiPushnotificationsCountGet$Params,
        context?: HttpContext
    ): Observable<DefaultSingleRecordReturnDto> {
        return this.apiPushnotificationsCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }
}
