/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DefaultMultiRecordReturnDto } from '../../models/default-multi-record-return-dto';

export interface ApiPushnotificationsGet$Params {
    /**
     * Query helper
     */
    query?: string;

    /**
     * Populate user
     */
    populateUser?: boolean;
}

export function apiPushnotificationsGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiPushnotificationsGet$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
    const rb = new RequestBuilder(rootUrl, apiPushnotificationsGet.PATH, 'get');
    if (params) {
        rb.query('query', params.query, {});
        rb.query('populateUser', params.populateUser, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<DefaultMultiRecordReturnDto>;
        })
    );
}

apiPushnotificationsGet.PATH = '/api/pushnotifications';
